namespace wg {


  export class ManageProtocols {
    public protocols: IWinemakingProtocol[] = [];
    private showProtocolsFromOthers: boolean = false;
    public other_protocols: IWinemakingProtocol[] = [];
    private showProtocolsFromAll: boolean = false;
    public all_protocols: IWinemakingProtocol[] = [];
    private showProtocolsFromShared: boolean = false;
    public selectedProtocol: IWinemakingProtocol = null;
    public readonly: boolean = false;

    public loading: boolean = false;
    public error: string = null;


    private static $inject = ['$rootScope', 'AuthService', 'ngDialog', '$http', '$state', '$timeout', '$translate'];

    constructor(private $rootScope: ng.IRootScopeService,
                private AuthService: IAuthService,
                private ngDialog: angular.dialog.IDialogService,
                private $http: ng.IHttpService,
                private $state: _IState,
                private $timeout: ng.ITimeoutService,
                private $translate: ng.translate.ITranslateService) {

      // get share_code from URL
      this.shared.code = this.$state?.params?.share_code;
      if (WG_debug) console.log("ManageProtocols constructor, with code: ", this.shared.code, this.$state);
      if (this.shared.code) {
        this.do_search(this.shared.code);
      }
    }

    // Fix/Normalize unit info: type, unit_type, icon, etc
    public normalize_protocols_info(protocol: IWinemakingProtocol | IWinemakingProtocol[]) {
      if (_.isArray(protocol)) {
        protocol.forEach((_protocol) => {
          this.normalize_protocols_info(_protocol);
        });
        return;
      }

      if (_.isNil(protocol)) {
        return;
      }

      _.defaults(protocol, {});

      // Calculate wine_type_text
      if (protocol.wine_type_id == WINE_TYPES.other.id) {
        protocol.wine_type_text = protocol.wine_type_other;
      } else if (protocol.wine_type_id) {
        let _wine_type = _.find(WINE_TYPES, {id: protocol.wine_type_id});
        if (_wine_type) {
          protocol.wine_type_text = this.$translate.instant(_wine_type.sref);
        }
      }

      //recursively go through all keys and remove "_template" to match the keys in the IWinemakingProtocol interface
      let normalize = (obj) => {
        if (obj) {
          Object.keys(obj).forEach(key => {

            if (typeof obj[key] === 'object') {

              if (Array.isArray(obj[key])) {
                for (let i = 0; i < obj[key].length; i++) {
                  normalize(obj[key][i]);
                }
              } else {
                normalize(obj[key]);
              }
            }

            if (key == ("stage_templates")) {
              obj[key.replace("stage_templates", "stages")] = obj[key];
              delete obj[key];
            }
            if (key == ("task_template")) {
              obj[key.replace("task_template", "tasks")] = obj[key];
              delete obj[key];
            }
            if (key == ("task_templates")) {
              obj[key.replace("task_templates", "tasks")] = obj[key];
              delete obj[key];
            }
            if (key == ("template_rules")) {
              obj[key.replace("template_rules", "rules")] = obj[key];
              delete obj[key];
            }
            if (key == ("rule_templates")) {
              obj[key.replace("rule_templates", "rules")] = obj[key];
              delete obj[key];
            }
            if (key == ("previous_task_template")) {
              obj[key.replace("previous_task_template", "previousTask")] = obj[key];
              delete obj[key];
            }

            if (key == ("parameter") && _.isNumber(obj[key])) {
              let _sensor = _.find(this.$rootScope.WGSensors?.sensors, {id: obj[key]});
              if (_sensor) {
                obj[key] = _sensor;
              }
            }


          });
        }
      }

      normalize(protocol);

      // if contains stages, order them using the linked-list variables "previous_stage_template"
      if (protocol.stages) {
        let currentStage: IWinemakingStage = _.find(protocol.stages, {previous_stage_template: null});
        let stageSequence = [];

        while (currentStage) {
          stageSequence.push(currentStage);
          currentStage = _.find(protocol.stages, {previous_stage_template: currentStage.id});
        }

        if (stageSequence.length == protocol.stages.length) {
          if (WG_debug) console.log("Stage sequence built", stageSequence, protocol.stages);
          protocol.stages = stageSequence;
        } else {
          console.warn("Error building stage sequence", stageSequence, protocol.stages);
        }
      }
    }

    private merge_protocols(new_all_protocols: IWinemakingProtocol[]) {
      // Select all protocols owned by AuthService.view_as_owner.id
      let new_protocols = _.remove(new_all_protocols, {owner: {id: this.AuthService.view_as_owner.id}});

      // get and remove all protocols belonging to user self or any in this.AuthService.user.shared_owners_relation
      // let new_other_protocols = _.concat(_.remove(new_all_protocols, {owner: {id: this.AuthService.user.id}})
      //     // , _.remove(new_all_protocols, {owner: {id: 173}})
      //     // , _.remove(new_all_protocols, {owner: {id: 339}})
      // );
      let new_other_protocols = _.remove(new_all_protocols, (protocol) => {
        return protocol.owner?.id == this.AuthService.user.id || _.find(this.AuthService.user.shared_owners_relation, {id: protocol.owner?.id});
      });


      if (_.isEmpty(this.protocols)) {
        this.protocols = new_protocols;
      } else {
        // Remove deleted entries
        _.forEach(this.protocols, (_protocol, _id) => {
          if (!_protocol) {
            return;
          }
          if (_.find(new_protocols, {id: _protocol.id})) {
            return;
          }
          console.info("Protocol Changed. Deleted: ", {del: _.clone(_protocol)});
          _.remove(this.protocols, {id: _protocol.id});
        });
        // Update or add changed/new protocols
        new_protocols.forEach((protocol) => {
          let _protocol = _.find(this.protocols, {id: protocol.id});
          if (_protocol) {
            _.assign(_protocol, protocol);
          } else {
            this.protocols.push(protocol);
          }
        });
      }

      if (_.isEmpty(this.other_protocols)) {
        this.other_protocols = new_other_protocols;
      } else {
        // Remove deleted entries from other_protocols
        _.forEach(this.other_protocols, (_protocol, _id) => {
          if (!_protocol) {
            return;
          }
          if (_.find(new_other_protocols, {id: _protocol.id})) {
            return;
          }
          console.info("Protocol Changed. Deleted: ", {del: _.clone(_protocol)});
          _.remove(this.other_protocols, {id: _protocol.id});
        });

        // Update or add changed/new other_protocols
        new_other_protocols.forEach((protocol) => {
          let _protocol = _.find(this.other_protocols, {id: protocol.id});
          if (_protocol) {
            _.assign(_protocol, protocol);
          } else {
            this.other_protocols.push(protocol);
          }
        });
      }

      if (_.isEmpty(this.all_protocols)) {
        this.all_protocols = new_all_protocols;
      } else {
        // Remove deleted entries from all_protocols
        _.forEach(this.all_protocols, (_protocol, _id) => {
          if (!_protocol) {
            return;
          }
          if (_.find(new_all_protocols, {id: _protocol.id})) {
            return;
          }
          console.info("Protocol Changed. Deleted: ", {del: _.clone(_protocol)});
          _.remove(this.all_protocols, {id: _protocol.id});
        });

        // Update or add changed/new all_protocols
        new_all_protocols.forEach((protocol) => {
          let _protocol = _.find(this.all_protocols, {id: protocol.id});
          if (_protocol) {
            _.assign(_protocol, protocol);
          } else {
            this.all_protocols.push(protocol);
          }
        });
      }
    }

    public reload(state_reload = false) {
      if (state_reload) {
        // Reload button clicked. Do a state reload. Else only data
        this.$state.reload();
        return;
      }
      console.info("Protocol Templates reloading");
      this.loading = true;
      this.$http.get<IWinemakingProtocol[]>('api/dashboard/winemaking/protocol_template/full/').then((response) => {
        if (WG_debug) console.log("ManageProtocols", response);

        let unhook1 = this.$rootScope.$watchGroup(['WGSensors.ready'], () => {
          // Called only when Sensors are ready
          if (!this.$rootScope.WGSensors?.ready) {
            return;
          }

          this.normalize_protocols_info(response.data);
          this.merge_protocols(response.data);

          console.info("Protocol Templates reloaded!", this.protocols);
          // Finished reading protocols. re-select the protocol if needed
          let _wanted_protocol = parseInt(this.$state.params?.protocol || "0");
          if (_wanted_protocol && this.selectedProtocol?.id != _wanted_protocol) {
            let _protocol = _.find(this.protocols, {id: _wanted_protocol});
            if (!_protocol) {
              _protocol = _.find(this.other_protocols, {id: _wanted_protocol});
              if (_protocol) {
                this.showProtocolsFromOthers = true;
              }
            }
            if (!_protocol) {
              _protocol = _.find(this.shared.protocols, {id: _wanted_protocol});
              if (_protocol) {
                this.showProtocolsFromShared = true;
              }
            }
            if (!_protocol) {
              _protocol = _.find(this.all_protocols, {id: _wanted_protocol});
              if (_protocol) {
                this.showProtocolsFromAll = true;
              }
            }
            if (_protocol) {
              this.selectProtocol(_protocol, false);
            } else {
              console.info("Protocol not found. Deselecting", _wanted_protocol);
            }
          }

          this.loading = false;
          unhook1();
        });

      }).catch((reason) => {
        console.warn("Error reloading protocols", reason);
        this.loading = false;
      });
    }

    public $onInit() {
      this.reload();

      // this.shared.code = this.$state?.params?.share_code;
      if (WG_debug) console.log("ManageProtocols $onInit, with code: ", this.shared.code, this.$state);
      // if (this.shared.code) {
      //   this.do_search(this.shared.code);
      // }
    }

    public $onChanges(changes) {
      console.log("manage-protocols", changes);
    }

    public createEditProtocol(protocol: IWinemakingProtocol = null) {
      if (protocol && protocol.owner?.id != this.AuthService.view_as_owner?.id) {
        console.warn("createEditProtocol: Unauthorized edit", protocol);
        return;
      }
      this.ngDialog.openConfirm({
        template: 'app/views/modals/create-or-edit-protocol.html',
        data: {protocol: protocol},
        controller: CreateOrEditProtocol,
        // controller: ['$scope', 'ngDialog', function ($scope, ngDialog) {
        // }],
      }).then((edited_protocol: IWinemakingProtocol) => {
        console.log("createEditProtocol", edited_protocol);
        this.normalize_protocols_info(edited_protocol);
        let _protocol = _.find(this.protocols, {id: edited_protocol.id});
        if (_protocol) {
          _.assign(_protocol, edited_protocol);
        } else {
          this.protocols.push(edited_protocol);
        }
        // For now, refresh state
        // this.$state.reload();
      }).catch((response) => {
        if (response === 'cancel' || response === '$document' || !response) {
          // Canceled
        } else {
          console.warn("createEditProtocol Failed", response);
        }
      });
    }


    public async duplicateProtocol(protocol: IWinemakingProtocol) {
      if (WG_debug) console.log("duplicateProtocol", protocol);

      this.$http.post<IWinemakingProtocol>(apiUrls.protocolTemplateURL + "clone/", protocol).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (WG_debug) console.log("Duplicated: ", response);
          this.normalize_protocols_info(response.data);
          this.protocols.push(response.data);
          // For now, refresh state
          // this.$state.reload();
        } else {
          console.error("Error duplicating protocol", response);
        }
      }).catch((error) => {
        console.error("Error duplicating protocol", error);
      });

      // this.ngDialog.openConfirm({
      //     plain: true,
      //     template: `
      //     <div class="ngdialog-message"> Are you sure you want to duplicate this protocol?
      //         <button class="btn btn-primary" ng-click="confirm()">Yes</button>
      //         <button class="btn btn-secondary" ng-click="closeThisDialog()">No</button>
      //     </div>`,
      //     data: {protocol: protocol},
      //     controller: ['$scope', 'ngDialog', function ($scope, ngDialog) {
      //
      //     }],
      // }).then((protocol: IWinemakingProtocol) => {
      //     const newProtocol = _.cloneDeep(protocol);
      //     newProtocol.id = this.protocols.length + 1;
      //     this.protocols = [...this.protocols, newProtocol];
      //     ManageProtocols.saveProtocols(this.protocols);
      // }).catch(() => {
      //
      // });

    }

    public async deleteProtocol(protocol: IWinemakingProtocol) {
      if (protocol.owner?.id !== this.AuthService.view_as_owner?.id) {
        console.warn("deleteProtocol: Unauthorized delete", protocol);
        return;
      }
      this.$http.delete(apiUrls.protocolTemplateURL + protocol.id + '/').then(() => {
        if (this.selectedProtocol?.id === protocol.id) {
          this.selectedProtocol = null
        }
        _.remove(this.protocols, {id: protocol.id});
        // For now, refresh state
        // this.$state.reload();
      }).catch((error) => {
        this.ngDialog.open({
          template: `<div class="ngdialog-message"> There was an error deleting the protocol </div>`,
          plain: true,
        });
      });
    }


    public selectProtocol(protocol: IWinemakingProtocol, should_update_url = true) {
      this.readonly = (protocol.owner?.id !== this.AuthService.view_as_owner?.id);
      this.selectedProtocol = protocol;
      if (should_update_url) {
        this.AuthService.update_url({
          'protocol': _.toString(protocol.id),
        }, false, false, false);
      }
    }

    private shared = {
      loading: false,
      code: null,
      protocols: []
    };

    private do_search(code = '') {
      this.shared.code = code;
      if (!code) {
        this.shared.protocols = [];
        this.showProtocolsFromShared = false;
        if (WG_debug) console.log("Shared Protocols cleared");
      } else {
        this.shared.loading = true;
        // TODO: Make a GET request for shared protocols using the provided code
        this.$timeout(() => {
          this.shared.protocols = _.filter(this.all_protocols, (protocol) => {
            // return protocol.code?.toLowerCase().includes(this.code.toLowerCase());
            // For now, get all protocols from user 173 or 339
            return protocol.owner?.id == 173 || protocol.owner?.id == 339;
          });
          this.shared.loading = false;
        }, 500);

        if (WG_debug) console.log("Shared Protocols got", this.shared.protocols);
        this.showProtocolsFromShared = true;
      }

      // add to URL
      this.AuthService.update_url({'share_code': code}, false, false, true);

      return;
    }
  }

  App.controller('ManageProtocols', ManageProtocols as any);

}


