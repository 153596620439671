// import {ICtrlScope, parseData} from "../utils/common";
// import {IRootScope} from "../utils/app";

/**
 * Created by pmec on 30/05/16.
 */

namespace wg {
  "use strict";

// function foo(f: IDataResult) {
//     console.log(f)
// }
//
// var f: IDataResult = {a: 1, b: 2};
// foo({a: 1, b: 2});
// var ff = (<any>f);
// console.log((<any>f).a);
// console.log(ff.a);
// console.log(f.value);
// console.log(ff.value);
// console.log(f['a']);
// console.log(f['b']);
  
  export interface IWGData {
    sensors: ISensor[];
    devices: IDevice[];
    
    getAPISenors(): ng.IPromise<ISensor[]>;
    
    getAPIDevices(): ng.IPromise<IDevice[]>;
    
    getAPIData(device: IDevice): ng.IPromise<IDataResponse[]>;
  }
  
  function _getDataURL($location): string {
    var data_base_url = '';
    return data_base_url;
    // var hostHttp = $location.host();
    // if (hostHttp === 'localhost' || hostHttp === '127.0.0.1' || hostHttp.indexOf('192.168.1.') > -1) {
    //     //if ($location.port() == 8000)
    //     data_base_url = 'api/proxy/wine.winegrid.com/';
    // }
    // return data_base_url
  }
  
  class WGData implements IWGData {
    public static $inject = [
      '$rootScope', '$q',
      '$http',
      '$location',
      'Sensors',
      'Devices',
      'DataUtils'
    ];
    
    public data_base_url = '';
    
    public sensors = [];
    public devices = [];
    
    constructor(private $rootScope: IRootScope, private $q: ng.IQService,
                private $http: angular.IHttpService,
                private $location: angular.ILocationService,
                private Sensors: ISensorResourceClass,
                private Devices: IDeviceResourceClass,
                private DataUtils) {
      console.log('WGData constructor');
      this.getDataURL()
    }
    
    getDataURL() {
      // var hostHttp = this.$location.host();
      // if (hostHttp === 'localhost' || hostHttp === '127.0.0.1' || hostHttp.indexOf('192.168.1.') > -1) {
      //     //if ($location.port() == 8000)
      //     this.data_base_url = 'api/proxy/wine.winegrid.com/';
      // }
      return this.data_base_url
    }
    
    getAPISenors(): ng.IPromise<ISensor[]> {
      var defer = this.$q.defer<ISensor[]>();
      this.$http.get<ISensor[]>('api/dashboard/sensors/all/').then((result) => {
        angular.forEach(result.data, (sensor) => {
          sensor.configs = <ISensorConfigs>parseData(<string><any>sensor.configs, {});
          sensor.name = this.DataUtils.translate(parseData(sensor.name)) || sensor.name;
          sensor.configs.accessLevel = sensor.configs.accessLevel || "admin";
          sensor.configs.manual = sensor.configs.manual || false;
        });
        this.sensors = result.data;
        defer.resolve(this.sensors);
      }).catch((result) => {
        console.error(result.data);
        defer.reject(result.data);
      });
      return defer.promise;
    }
    
    getAPIDevices(): ng.IPromise<IDevice[]> {
      var defer = this.$q.defer<IDevice[]>();
      // this.Devices.simple().$promise.then((data: ng.resource.IResourceArray<IDevice>) => {
      this.Devices.simple().$promise.then((data: ng.resource.IResourceArray<IDeviceResource>) => {
        for (let device of data) {
          this.devices.push(device);
        }
        defer.resolve(this.devices);
      });
      return defer.promise;
    }
    
    getAPIData(device: IDevice): ng.IPromise<IDataResponse[]> {
      return undefined;
    }
  }
  
  interface IDataProvider extends angular.IServiceProvider {
    api_key: string;
    data_base_url: string;
  }
  
  interface IDataGetMethod<T> {
    (device: IDevice, sensor: ISensor): ng.IPromise<T>;
    
    (device: IDevice, sensor: ISensor, params: IDataParams): ng.IPromise<T>;
    
    (device: IDevice, sensor: ISensor, params: IDataParams, data: Object): ng.IPromise<T>;
  }
  
  export interface IDataParams {
    api_key?: string;
    b?: string | number; // begin
    e?: string | number; // end
    s?: number;        // page_size
    p?: number;        // page
    l?: number;        // limit
    q?: Object;        // query
    f?: string;        // format    available_formats:['list', 'object']
    m?: number;        // matching
    k?: number;        // last_known
    n?: string;        // file_name
    c?: Object;        // conversions
    
    [key: string]: any;// other
  }
  
  export interface IDataClass {
    // all: IDataMethod<IDataResponse>;
    // last_known: IDataMethod<IDataResponse>;
    // get: IDataMethod<IDataResponse>;
    get: IDataGetMethod<IDataResult[]>;
    
    // post: IDataGetMethod<IDataResult[]>;
    post(device: IDevice, sensor: ISensor, params: IDataParams, data: Object): ng.IPromise<IDataPostResponse>;
    
    getUrlExport(device: IDevice, sensor: ISensor, min: string | number, max: string | number, filename: string, extension: string): string;
  }
  
  class DataClass implements IDataClass {
    getUrlExport(device: wg.IDevice, sensor: wg.ISensor, begin: string | number, end: string | number, filename: string, extension: string): string {
      var key = this.provider.api_key;
      var path = `data/${device.path}/${sensor.stream}`;
      var exportName = `${filename}.${extension}`;
      var q: Object = ["datetime", {"payload": ["timestamp", "value"]}];
      if (angular.isDefined(sensor.configs)) {
        if (angular.isDefined(sensor.configs.exportQuery)) {
          q = sensor.configs.exportQuery;
        } else if (angular.isDefined(sensor.configs.query)) {
          q = sensor.configs.query;
        }
      }
      q = JSON.stringify(q);
      // TODO: Change this hostname to use dynamic Storage_API server
      var url = `https://wine.winegrid.com/${path}?b=${begin}&e=${end}&q=${q}&n="${exportName}"&api_key=${key}`;
      console.log(url);
      return url;
    }
    
    constructor(private provider: IDataProvider,
                private $http: ng.IHttpService,
                private $q: ng.IQService,
                private AuthService: IAuthService) {
    }
    
    // private _all(device: IDevice, sensor: ISensor): ng.IPromise<IDataResponse>;
    // private _all(device: IDevice, sensor: ISensor, params: Object): ng.IPromise<IDataResponse>;
    // private _all(device: IDevice, sensor: ISensor, params: Object, data: Object): ng.IPromise<IDataResponse>;
    // private _all(...args): ng.IPromise<IDataResponse> {
    //     var device: IDevice, sensor: ISensor;
    //     var params, data;
    //     params = {
    //     };
    //     switch (args.length) {
    //         case 4:
    //             data = args[1];
    //         //fallthrough
    //         case 3:
    //             angular.extend(params, args[2]);
    //         //fallthrough
    //         case 2:
    //             device = args[0];
    //             sensor = args[1];
    //             break;
    //     }
    //     console.log(`all ${args.length}`);
    //     return this._get(device, sensor, params, data);
    // }
    // private _last_known(device: IDevice, sensor: ISensor): ng.IPromise<IDataResponse>;
    // private _last_known(device: IDevice, sensor: ISensor, params: Object): ng.IPromise<IDataResponse>;
    // private _last_known(device: IDevice, sensor: ISensor, params: Object, data: Object): ng.IPromise<IDataResponse>;
    // private _last_known(...args): ng.IPromise<IDataResponse> {
    //     var device: IDevice, sensor: ISensor;
    //     var params, data;
    //     params = {
    //         k: 1,
    //         m: 1
    //     };
    //     switch (args.length) {
    //         case 4:
    //             data = args[1];
    //         //fallthrough
    //         case 3:
    //             angular.extend(params, args[2]);
    //         //fallthrough
    //         case 2:
    //             device = args[0];
    //             sensor = args[1];
    //             break;
    //     }
    //     console.log(`last_known ${args.length}`);
    //     return this._get(device, sensor, params, data);
    // }
    
    public parse_params(URL: string): IDataParams {
      
      let ret: IDataParams = {};
      let _params = deparam(URL);
      let num_list = ['b', 'e', 's', 'p', 'l', 'm', 'k'];
      for (let k in _params) {
        if (num_list.indexOf(k) > -1) {
          let n = Number(_params[k]);
          ret[k] = isNaN(n) ? _params[k] : n;
        } else if (k == 'q') {
          ret[k] = parseData(_params[k]);
        } else {
          ret[k] = _params[k];
        }
      }
      return ret;
    }
    
    private __get(get_defer: ng.IDeferred<IDataResult>, get_promises: ng.IPromise<IDataResult>[], device: IDevice, sensor: ISensor, params?: IDataParams, data?: Object): void {
      var defer = this.$q.defer();
      var _params: IDataParams = {
        b: '1y',
        api_key: this.provider.api_key
      };
      if (!_params.api_key) {
        if (WG_debug) console.error('Invalid API_Key for Get_2', get_defer);
        if (this.AuthService && this.AuthService.user && this.AuthService.user.api_key) {
          _params.api_key = this.AuthService.user.api_key;
        }
      }
      if (params === undefined) {
        params = _params;
      } else {
        angular.extend(_params, params);
        params = _params;
      }
      if (angular.isDefined(params.q)) {
        params.q = JSON.stringify(params.q);
      }
      if (angular.isDefined(params.c)) {
        params.c = JSON.stringify(params.c);
      }
      // if (data === undefined) {
      //     dataapirin = [];
      // }
      get_promises.push(defer.promise);
      var config: ng.IRequestShortcutConfig = {
        params: params,
      };
      var path = `data/${device.path}/${sensor.stream}`;
      this.$http.get<IDataResponse>(path, config).then((result) => {
        // var data = [];
        // data.concat(result.data.results);
        defer.resolve(result.data.results);
        if (result.data.next != null) {
          let next_params = this.parse_params(result.data.next);
          this.__get(get_defer, get_promises, device, sensor, next_params, data);
        } else {
          this.$q.all<IDataResult>(get_promises).then((results: IDataResult[]) => {
            // var result_data: IDataResult[] = [];
            // for (let r of results) {
            //   result_data.push(r);
            // }
            get_defer.resolve(results[0]);
          });
        }
      });
    }
    
    private _get(device: IDevice, sensor: ISensor, params?: IDataParams, data?: Object): ng.IPromise<IDataResult[]> {
      var defer: ng.IDeferred<IDataResult> = this.$q.defer();
      var promises: ng.IPromise<IDataResult>[] = [];
      this.__get(defer, promises, device, sensor, params, data);
      // @ts-ignore
      return defer.promise;
    }
    
    public post(device: IDevice, sensor: ISensor, params?: IDataParams, data?: Object): ng.IPromise<IDataPostResponse> {
      var defer: ng.IDeferred<any> = this.$q.defer();
      var _params: IDataParams = {
        api_key: this.provider.api_key
      };
      if (!_params.api_key) {
        if (WG_debug) console.error('Invalid API_Key for Post');
        // if(AuthService.user && AuthService.user.api_key)
        // _params.api_key = AuthService.user.api_key;
      }
      if (params === undefined) {
        params = _params;
      } else {
        angular.extend(_params, params);
        params = _params;
      }
      var config: ng.IRequestShortcutConfig = {
        params: params,
      };
      var path = `data/${device.path}/${sensor.stream}`;
      this.$http.post<IDataPostResponse>(path, data, config).then((result) => {
        defer.resolve(result.data);
      }, (reason) => {
        console.log('Data post error', reason);
        defer.reject(reason);
      });
      return defer.promise;
    }
    
    // all: IDataMethod<IDataResponse> = this._all;
    // last_known: IDataMethod<IDataResponse> = this._last_known;
    get: IDataGetMethod<IDataResult[]> = this._get;
  }
  
  class DataProvider implements IDataProvider {
    api_key: string = '';
    data_base_url: string = '';
    
    constructor() {
      // @ts-ignore
      this.$get.$inject = ['$http', '$q', '$resource', '$location', 'AuthService']
    }
    
    $get($http: ng.IHttpService, $q: ng.IQService, $resource: ng.resource.IResourceService, $location: angular.ILocationService, AuthService: IAuthService): IDataClass {
      // Check if user is logged in
      if (!AuthService.isLogged) {
        if (WG_debug) console.warn('User is not logged in');
        return null;
      }
      this.api_key = AuthService.user?.api_key;
      if (!this.api_key) {
        if (WG_debug) console.error('Invalid API_Key for DataClass');
        return null;
      }
      this.data_base_url = _getDataURL($location);
      return new DataClass(this, $http, $q, AuthService);
    }
  }
  
  // export interface IDataResponseResource extends IDataResponse, ng.resource.IResource<IDataResponseResource> {
  export interface IDataResponseResource extends ng.resource.IResource<IDataResponse> {
  }
  
  export interface IDataResponseResourceClass extends ng.resource.IResourceClass<IDataResponseResource> {
    all: ng.resource.IResourceMethod<IDataResponseResource>;
    last_known: ng.resource.IResourceMethod<IDataResponseResource>;
  }
  
  DataFactory.$inject = ['$resource', '$location'];
  
  function DataFactory($resource: ng.resource.IResourceService, $location: angular.ILocationService): IDataResponseResourceClass {
    // function DataFactory($resource: ng.resource.IResourceService, $location: angular.ILocationService): ng.resource.IResourceClass<ng.resource.IResource<any>> {
    var data_base_url = _getDataURL($location);
    return $resource<IDataResponseResource, IDataResponseResourceClass>(`${data_base_url}api/data/:path`,
        {
          path: '@path',
          api_key: '@apiKey'
        }, {
          all: {method: 'get', isArray: true, params: {route: 'all'}}
        });
  }
  
  // export interface ISensorResource extends ISensor, ng.resource.IResource<ISensorResource> {
  export interface ISensorResource extends ng.resource.IResource<ISensor> {
  }
  
  export interface ISensorResourceClass extends ng.resource.IResourceClass<ISensorResource> {
    all: ng.resource.IResourceArrayMethod<ISensorResource>;
  }
  
  SensorsFactory.$inject = ['$resource'];
  
  // function SensorsFactory($resource: ng.resource.IResourceService): ISensorResourceClass {
  function SensorsFactory($resource: ng.resource.IResourceService): ng.resource.IResourceClass<ng.resource.IResource<any>> {
    return $resource<ISensorResource, ISensorResourceClass>('api/dashboard/sensors/:id:route/',
        {
          id: '@id',
          route: '@route'
        }, {
          all: {method: 'get', isArray: true, params: {route: 'all'}}
        });
  }
  
  // export interface IDeviceResource extends IDevice, ng.resource.IResource<IDeviceResource> {
  export interface IDeviceResource extends ng.resource.IResource<IDevice> {
  }
  
  export interface IDeviceResourceClass extends ng.resource.IResourceClass<IDeviceResource> {
    all: ng.resource.IResourceArrayMethod<IDeviceResource>;
    simple: ng.resource.IResourceArrayMethod<IDeviceResource>;
  }
  
  DevicesFactory.$inject = ['$resource'];
  
  // function DevicesFactory($resource: ng.resource.IResourceService): IDeviceResourceClass {
  function DevicesFactory($resource: ng.resource.IResourceService): ng.resource.IResourceClass<ng.resource.IResource<any>> {
    return $resource<IDeviceResource, IDeviceResourceClass>('api/dashboard/devices/:id:route/',
        {
          id: '@id',
          route: '@route'
        }, {
          all: {method: 'get', isArray: true, params: {route: 'all'}},
          simple: {method: 'get', isArray: true, params: {route: 'simple'}},
        });
  }
  
  export interface IDataAPI {
    api_key: string;
    default_page_size: number;
  }

  App.config(['$resourceProvider', function ($resourceProvider: ng.resource.IResourceServiceProvider) {
    // Don't strip trailing slashes from calculated URLs
    $resourceProvider.defaults.stripTrailingSlashes = false;
  }]);
  // App.factory('Data', DataFactory);
  App.provider('Data', DataProvider);
  App.factory('Sensors', SensorsFactory);
  App.factory('Devices', DevicesFactory);
  App.service('WGDataService', WGData);
}