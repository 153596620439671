namespace wg {
  class wgInfoTip implements ng.IDirective {

    public restrict = 'E';
    scope = {
      textContainerId: '@'
    }

    link(scope: ng.IScope, element: ng.IAugmentedJQuery) {
      const button = angular.element('<button><i class="fa fa-info-circle"></i></button>');
      element.css('position', 'relative');
      element.append(button);
    }

    static factory(): ng.IDirectiveFactory {
      const directive = () => new wgInfoTip();
      return directive;
    }
  }

  App.directive('wgInfoTip', wgInfoTip.factory());
}