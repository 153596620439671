/**
 * Created by pmec on 30/08/17.
 */
namespace wg {
  // Used by Device-Edit
  App.controller('EditDeviceModalInstance', ['$rootScope', '$scope', '$http', '$filter', '$state', 'WGApiData', 'APIUtils',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $filter: ng.IFilterService, $state: _IState, WGApiData: WGApiData, APIUtils: APIUtils) {

      let parentRet: IReturnResult = {
        loading: false,
        result: '',
        message: '',
      };
      $scope.ret = parentRet;

      if (!$scope.ngDialogData)
        $scope.ngDialogData = {};

      // console.log('EditDeviceModalInstance', $scope.ngDialogData);
      $scope.device = $scope.device || $scope.ngDialogData?.device;
      $scope.addToGraph = $scope.ngDialogData?.addToGraph;

      if (!$scope.device) {
        // $scope.cancel('No device provided');
        // $scope.cancel(1);
        // $scope.closeThisDialog(1);
        return;
      }
      //if (WG_debug) console.log("Opening Edit Device modal", $state);

      $scope.spitData = function () {
        spitData("EditDevice SpitData", $scope, $scope.device);
      }

      let ret_key = $scope.ngDialogData?.result || 'editDeviceRet';
      if ($scope.$parent?.[ret_key]) {
        $scope.$parent[ret_key] = parentRet;
      } else if ($scope.$parent?.$parent?.[ret_key]) {
        $scope.$parent.$parent[ret_key] = parentRet;
      }

      let lkm = $rootScope.lastKnownMessages?.[$scope.device.uuid] || {};
      let configs = parseData(lkm.configs?.payload?.value, {});

      $scope.data = {
        name: $scope.device.name,
        description: $scope.device.description,
        unit_type: $scope.device.unit_type,
        model: $scope.device.model,
        internal_name: $scope.device.internal_name,
        sn: $scope.device.sn,
        uuid: $scope.device.uuid,
        iid: $scope.device.iid,
        path: $scope.device.path,
        lat: $scope.device.lat,
        lon: $scope.device.lon,
        lkm: lkm,
        configs: configs,
        configs_str: $rootScope.toPrettyJSON(configs, 2),
        // wifi_ssid: (configs && configs.wifi && configs.wifi.SSID && configs.wifi.SSID !== "Watgrid") ? configs.wifi.SSID : "My_SSID",
      };
      // console.log('EditDeviceModalInstance device', device);
      // console.log('EditDeviceModalInstance data', $scope.data);

      $scope.lkm_as_table = false;
      $scope.lkm_table = {};
      $scope.lkm_table_keys = [];

      // console.log('EditDeviceModalInstance lkm', lkm);
      _.forEach(lkm, function (_lkm, stream) {
        // Don't show configs or SET_ALARM/REMOVE_ALARM
        if (stream === 'configs' || stream.endsWith('ALARM')) {
          return;
        }
        //console.log('lkm', key, sensor);
        if (_.isNil(_lkm?.payload?.value) || _lkm.payload.value === '') {
          return;
        }


        let add_to_table = (internal_name: string, value, graph, timestamp: number) => {

          $scope.lkm_table[timestamp + '-' + internal_name] = {
            internal_name: internal_name,
            graph: graph,
            value: value,
            timestamp: timestamp,
            addToGraph: () => {
              let parent_addToGraph: GraphController['addToGraph'] = $scope.addToGraph
                  || $scope.$parent?.addToGraph
                  || $scope.$parent?.$parent?.addToGraph
                  || $scope.$parent?.$parent?.$parent?.addToGraph
                  || $scope.$parent?.$parent?.$parent?.$parent?.addToGraph;
              if (WG_debug) console.log('addToGraph', $scope, parent_addToGraph);

              if (parent_addToGraph) {
                parent_addToGraph($scope.device.uuid, internal_name);
              }

              // if ($state.current.name === 'app.devices.units') {
              //   console.log('addToGraph', internal_name);
              //   if (_.isNil($state.params.sensors)) {
              //     $state.params.sensors = [];
              //   } else if (_.isString($state.params.sensors)) {
              //     $state.params.sensors = [$state.params.sensors];
              //   }
              //   $state.params.sensors.push(internal_name);
              //   $state.params.sensors = _.uniq($state.params.sensors)
              //   $state.go($state.current.name, $state.params);
              // } else if ($state.current.name === 'app.devices.dashboard') {
              //   console.log('addToGraph', internal_name);
              //   // Add to URL
              //   let i = 1;
              //   for (; i < 19; i++) {
              //     if (!$state.params['device' + i]) {
              //       // First free parameter found
              //       break;
              //     }
              //     if ($state.params['device' + i] == $scope.device.uuid && $state.params['param' + i] == internal_name) {
              //       // Already filled
              //       i = 999999;
              //       break;
              //     }
              //   }
              //   if (i < 19) {
              //     $state.params['device' + i] = $scope.device.uuid;
              //     $state.params['param' + i] = internal_name;
              //     $state.go($state.current.name, $state.params);
              //   }
              // }
            }
          }
        }

        let timestamp = _lkm.payload?.timestamp || _lkm.timestamp || 0;
        let sensor = WGApiData.WGSensors.sensors_name[stream];
        if (sensor && !_.isObject(_lkm.payload.value)) {
          let value = _lkm.payload.value;
          let graph = sensor.configs?.graph;
          add_to_table(stream, value, graph, timestamp);
        } else {
          _.forEach(WGApiData.WGSensors.sensors_name, (_sensor, internal_name) => {
            if (stream === _sensor.stream && _sensor.configs.sub_query) {
              // console.log('complex sensor', key, stream, _sensor.configs.sub_query, lkm_value.payload.value, _sensor);
              let value = select(_sensor.configs.sub_query, _lkm.payload.value)[0];
              let graph = _sensor.configs.graph;
              add_to_table(internal_name, value, graph, timestamp);
            }
          });
        }

      });
      // Order by .timestamp and extract keys from $scope.lkm_table and

      // let _sorted_lkm_table = _.orderBy($scope.lkm_table, ['timestamp'], ['desc']);
      $scope.lkm_table_keys = _.keys($scope.lkm_table).sort().reverse();

      $scope.wellFormed = true;
      $scope.enable_edit_configs = false;
      $scope.enable_edit_configs_collapsed = false;

      $rootScope.$watch('lastKnownMessages["' + $scope.device.uuid + '"].configs.payload.value', (_new_value, _old_value) => {
        console.log('lkm.configs changed!', _new_value);
        if (_.isNil(_new_value) || _.isEqual(_new_value, _old_value)) {
          return;
        }
        $scope.data.configs = parseData(lkm?.configs?.payload?.value, {});
      }, true);

      $scope.$watch('data.configs', (_new_value, _old_value) => {
        if (_.isNil(_new_value) || _.isEqual(_new_value, _old_value)) {
          return;
        }
        console.log('Converting data.configs', _new_value);
        if ($scope.data) {
          $scope.data.configs_str = $rootScope.toPrettyJSON(_new_value, 2);
        }
      }, true);
      $scope.$watch('data.configs_str', (_new_value, _old_value) => {
        if (_.isNil(_new_value) || _.isEqual(_new_value, _old_value)) {
          return;
        }
        if ($scope.data) {
          let _parsed = parseData(_new_value, null);
          if (_parsed != null) {
            $scope.data.configs = _parsed;
            $scope.wellFormed = true;
          } else {
            $scope.wellFormed = false;
          }
        }
      }, true);

      $scope.edit_configs = (enable = true) => {
        $scope.enable_edit_configs = enable;
        if (!enable) {
          $scope.data.configs = parseData(lkm?.configs?.payload?.value, {});
        }
      };
      $scope.save_configs = (_configs) => {
        $scope.enable_edit_configs = false;

        let value = parseData(_configs || $scope.data.configs, null);
        if (value == null) {
          console.error('invalid configs', _configs, $scope.data.configs);
          return;
        }

        APIUtils.save_device_configs($scope.device, value,
            (response) => {
              if (WG_debug) console.log("Saved", response);
            }, (response) => {
              console.warn("Error saving configs:", response);
              $scope.data.configs = parseData(lkm?.configs?.payload?.value, {});
            });
      };


      /**
       * Calls reload if configured to do so
       */
      $scope.reload = () => {
        console.log('reload');
        var state = $state.current.name;
        var params = $state.params;
        params.device_uuid = $scope.device.uuid;
        var options = {
          reload: true,
          notify: true
        };

        if ($scope.ngDialogData?.reload) {
          if ($scope.ngDialogData.reload.state) {
            state = $scope.ngDialogData.reload.state;
          }
          if ($scope.ngDialogData.reload.params) {
            params = $scope.ngDialogData.reload.params;
          }
          if ($scope.ngDialogData.reload.options) {
            options = $scope.ngDialogData.reload.options;
          }
        }
        $state.go(state, params, options);
      }

      $scope.save = (data) => {
        parentRet.loading = true;


        // Copy only safe parameters
        var _data = {};
        for (let _key of ['name', 'description', 'lat', 'lon']) {
          _data[_key] = data[_key];
        }

        // console.log(device.id, data, _data);
        $http.patch<IDevice>('api/dashboard/devices/' + $scope.device.id + '/', _data).then(
            function (response) {
              console.log('Device updated', response);

              $rootScope.WGDevices.merge_entry(response.data, true);

              // $rootScope.WGDevices.changed = true;
              // $rootScope.WGDevices.merge_entry(response.data);

              parentRet.loading = false;
              parentRet.result = 'success';

              let return_result = {
                item_data: response.data
              };

              setTimeout(function () {
                // $rootScope.WGApiData.update_changed_data();

                if (parentRet.result === 'success' && $scope.confirm) {
                  $scope.confirm(return_result);
                } else if ($scope.closeThisDialog) {
                  $scope.reload();
                  $scope.closeThisDialog(return_result);
                } else {
                  $scope.reload();
                  $scope.close(0);
                }

                // ngDialog.close(0);
              }, 600);
            }, function (response) {
              console.error('then onRejected', response);
              parentRet.result = 'error';
              parentRet.loading = false;
            });
      };
      $scope.cancel = function (value) {
        parentRet.loading = false;
        parentRet.result = 'error';
        parentRet.message = 'cancel';

        $scope.closeThisDialog(value);
      };
    }]);

  App.controller('EditDeviceConnectionModalInstance', ['$rootScope', '$scope', '$http', 'WGApiData', 'APIUtils',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, WGApiData: WGApiData, APIUtils: APIUtils) {


      let parentRet: IReturnResult = {
        loading: false,
        result: '',
        message: '',
      };

      if (typeof $scope.ngDialogData?.result === 'object') {
        parentRet = $scope.ngDialogData.result;
      } else {
        let ret_key = $scope.ngDialogData?.result || 'editDeviceConnectionRet';
        if ($scope.$parent?.[ret_key]) {
          $scope.$parent[ret_key] = parentRet;
        } else if ($scope.$parent?.$parent?.[ret_key]) {
          $scope.$parent.$parent[ret_key] = parentRet;
        }
      }
      $scope.ret = parentRet;

      $scope.devices = WGApiData.extractDevices($scope.device || $scope.ngDialogData?.device) as IDevice[];
      $scope.device = $scope.devices[0] as IDevice;

      let lkm: ILastKnownMessages = $rootScope.lastKnownMessages?.[$scope.device.uuid] || {};
      let configs: IDeviceLKMConfigs = parseData(lkm?.configs?.payload?.value, {});

      $scope.wifiObj = {isAdv: false};

      $scope.connection_types = {
        ALL: 'All',
        WIFI: 'WiFi',
        LORA: 'LoRa',
        LORA_P2P: 'LoRa_P2P',
        LORA_WAN: 'LoRa_WAN',
      };
      $scope.ips = {
        DHCP: 'DHCP',
        STATIC: 'Static'
      };

      // Data show on Modal to change/set on selected devices. Get from 1st passed device
      $scope.data = {
        connection: null, // Don't change
        ip_settings: {
          mode: configs.wifi?.MODE == $scope.ips.STATIC ? $scope.ips.STATIC : $scope.ips.DHCP
        },
        wifi_ssid: configs.wifi?.SSID || lkm.SSID?.payload?.value || "My_SSID",
        wifi_pass: "nullnull",
      };

      if (_.isNil($scope.data.wifi_ssid)) {
        $scope.data.wifi_ssid = "My_SSID";
      }

      if (WG_debug) console.log('EditDeviceConnectionModalInstance', $scope.devices, $scope.device, $scope.data);
      // Sets to use Wi-Fi or LoRa
      $scope.connection_set_mode = function (comm_mode) {
        $scope.data.connection = comm_mode;
        if (comm_mode === $scope.connection_types.WIFI && !angular.isDefined($scope.data.ip_settings.mode)) {
          $scope.data.ip_settings.mode = $scope.ips.DHCP;
        }
        // else if($scope.data.ip_settings.mode){
        //   delete $scope.data.ip_settings.mode;
        // }
      };

      $scope.reset = function () {
        $scope.data.wifi_ssid = "Winegrid";
        $scope.data.wifi_pass = "nullnull";
      }

      $scope.save = function (data) {
        parentRet.loading = true;

        // let d = $scope.data;
        // console.log('Wi-Fi', data.wifi_ssid, data.wifi_pass);

        if (_.isEmpty(data['wifi_ssid']) || _.isEmpty(data['wifi_pass'])
            || data['wifi_ssid'] === "My_SSID" || data['wifi_pass'] === "nullnull") {
          configs['wifi'] = null;
        } else if (!_.isEmpty(data['wifi_ssid']) && !_.isEmpty(data['wifi_pass'])) {
          configs['wifi'] = {
            SSID: data['wifi_ssid'],
            PASS: data['wifi_pass']
          };
          if (data.ip_settings.mode === $scope.ips.STATIC) {
            _.assign(configs['wifi'], data.ip_settings);
            // configs['mode']=null;

          }
        }
        if (data.connection) {
          if (data.connection === $scope.connection_types.WIFI) {
            configs.mode = $scope.connection_types.WIFI;
          } else if (data.connection === $scope.connection_types.LORA) {
            configs.mode = $scope.connection_types.LORA;
          } else if (data.connection === $scope.connection_types.LORA_P2P) {
            configs.mode = $scope.connection_types.LORA_P2P;
          } else if (data.connection === $scope.connection_types.LORA_WAN) {
            configs.mode = $scope.connection_types.LORA_WAN;
          }
        }

        console.log('configs', configs);

        _.forEach($scope.devices, (device: IDevice) => {
          if (!device.iid || !device.path) {
            console.error('device iid not found')
            return;
          }
          if (!$rootScope.DataUtils.can_device_config_connection(device)) {
            if (WG_debug) console.warn('device cannot config connection');
            return;
          }

          APIUtils.save_device_configs(device, configs, (response) => {
            console.log('Device configs saved', response);
            parentRet.loading = false;
            parentRet.result = 'success';
              setTimeout(function () {
                if (parentRet.result === 'success' && $scope.confirm) {
                  $scope.confirm();
                } else if ($scope.closeThisDialog) {
                  $scope.closeThisDialog();
                } else {
                  $scope.close(0);
                }
              }, 600);
          }, (response) => {
            console.error('Device configs save failed!', response);
            parentRet.loading = false;
            parentRet.result = 'error';
          });

          // let lkm = $rootScope.lastKnownMessages?.[device.uuid] || device.lkm;
          // let dev_configs = _.assign({}, parseData(lkm?.configs?.payload?.value, {}), new_configs);
          // // _.merge(dev_configs, new_configs);
          //
          // let payload = {
          //   iid: device.iid,
          //   value: dev_configs,
          //   timestamp: new Date().getTime()
          // };
          // let url = $rootScope.base_url + 'data/' + device.path + '/configs?api_key=' + $rootScope.apiKey;
          // console.log('save configs', 'url', url, 'payload', payload);
          // $http.post(url, payload).then(
          //     function (response) {
          //       onFulfilled(response);
          //     }, function (response) {
          //       onRejected(response);
          //     });
        });

      };
      $scope.cancel = function (value) {
        parentRet.result = '';
        parentRet.loading = false;
        $scope.closeThisDialog(value);
      };
      //  /   /   /   /   DEV
      $scope.logScope = function () {
        console.log("##  $scope  ##", $scope);
      };
    }]);
}