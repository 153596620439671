/**=========================================================
 * Module: admin-users.js
 * Users management
 =========================================================*/
namespace wg {
  App.controller('AdminUsersController', ['$rootScope', '$scope', '$http', '$timeout', 'ngTableParams', 'ngDialog',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $timeout: ng.ITimeoutService, ngTableParams: NgTable.ITableParamsConstructor<any>, ngDialog: angular.dialog.IDialogService) {
      $scope.users = [];
      $scope.showingFrom = 0;
      $scope.showingTo = 0;
      $scope.search = '';
      $scope.loading = false;
      $scope.actions = {action: null, delete: 'DELETE'};
      $scope.filterRoles = [{'id': '', 'title': ''},
        {'id': 'admin', 'title': 'admin'},
        {'id': 'staff', 'title': 'staff'},
        {'id': 'user', 'title': 'user'},
        {'id': 'deactivated', 'title': 'deactivated'}
      ];
      // $scope.editRoles = ['admin', 'staff', 'distributor', 'user', 'deactivated'];
      $scope.editRoles = ['distributor', 'user', 'deactivated'];
      $scope.filterGroups = [{'id': '', 'title': ''}];
      $scope.editGroups = [];
      $scope.checkboxes = {checked: false, items: {}};
      $scope.selection = [];
      $scope.getGroups = function () {
        $http.get('api/auth/groups/all/', {}).then(
            function (response) {
              var data = response.data;
              $scope.editGroups = data;
              $scope.filterGroups.length = 0;
              $scope.filterGroups.push({'id': '', 'title': ''});
              angular.forEach(data, function (group) {
                $scope.filterGroups.push({'id': group.name, 'title': group.name});
              });
            }, function (response) {
              console.error(response);
            });
      };

      $scope.updateTotals = function (total) {
        // if(WG_debug) console.log('updateTotals', total);
        $scope.tableParams.total(total);
        $scope.showingFrom = ($scope.tableParams.page() - 1) * $scope.tableParams.count() + 1;
        if ($scope.showingFrom > total)
          $scope.showingFrom = total;
        $scope.showingTo = $scope.showingFrom - 1 + $scope.tableParams.count();
        if ($scope.showingTo > total)
          $scope.showingTo = total;
      };

      $scope.tableParams = new ngTableParams({
        page: 1,
        count: 10,
        sorting: {
          username: 'asc' // initial sorting
        },
        filter: {}
      }, {
        total: 0,
        getData: (params) => {
          $scope.loading = true;
          var search = $scope.search;
          var filter = '';
          var obj = params.filter();
          for (var key in obj) {
            // if (obj.hasOwnProperty(key)) {
            if (angular.isDefined(obj[key]) && obj[key] !== '')
              filter += key + '=' + obj[key] + '&';
            // }
          }
          var query = '';
          if (params.orderBy() + '' !== '') {
            query += '&ordering=' + params.orderBy();
          }
          if (angular.isDefined(search) && search !== '') {
            query += '&search=' + search;
          } else {
            query += '&page=' + params.page();
          }
          if (filter !== '') {
            filter = filter.substring(0, filter.length - 1);
            query += '&' + filter;
          }
          $scope.getGroups();
          return $http.get<IDataResponse<IUser>>('api/auth/users/?page_size=' + params.count() + query, {}).then(
              (response) => {
                $scope.users = response.data.results;
                $scope.changeAllSelection(false);
                //angular.forEach(data, function(user) {
                //  var obj = _.find($scope.users,  {id: user.id});
                //  if (angular.isDefined(obj)) {
                //    angular.extend(obj, user);
                //    $scope.checkboxes.items[user.id] = false;
                //  } else {
                //    $scope.users.push(user);
                //  }
                //});
                // update table params
                $scope.loading = false;
                $scope.updateTotals(response.data.count);
                return $scope.users;
              }, function (response) {
                $scope.loading = false;
                console.error(response);
              });
        }
      });
      $scope.reload = function () {
        $rootScope.$broadcast('adminReload');
      };
      $scope.$on('adminReload', function (event, args) {
        console.log('adminReload', 'admin-users');
        $scope.tableParams.reload();
      });
      $scope.changeSelection = function (user) {
        var obj = _.find($scope.selection, {id: user.id});
        if (angular.isDefined(obj)) {
          $scope.selection = _.without($scope.selection, user);
        } else {
          $scope.selection.push(user);
        }
      };
      $scope.newUser = function () {
        let dialog = ngDialog.open({
          template: 'adminUserNewDialog',
          data: {}
        });
        dialog.closePromise.then(
            function (dialogData) {
              $scope.reload();
            });
      };
      $scope.edit = function (user) {
        $scope.changeAllSelection(false);
        user.editGroups = objArrayDiff(user.groups, $scope.editGroups, 'id');
        //user.editGroupsNames = _.map($scope.editGroups, 'name');
        user.groupsNames = _.map(user.editGroups, 'name');
        //user.selectedGroupsNames = _.map(user.groups, 'name');
        user.$edit = true;
      };
      $scope.save = function (user) {
        if (_.isEmpty(user.company)) {
          delete user.company;
        }
        $http.patch<IUser>('api/auth/users/' + user.id + '/', user).then(
            function (response) {
              user.$edit = false;
              angular.extend(user, response.data);
            }, function (response) {
              console.error(response);
              if (response.status === 400 && response.data) {
                for (var key in response.data) {
                  var specificField = $('#id-' + key + '-' + user.id).parsley();
                  var i = 0;
                  angular.forEach(response.data[key], function (error) {
                    try {
                      specificField.addError('error-' + i, {message: error});
                      // window.ParsleyUI.addError(specificField, 'error-' + i, error);
                    } catch (e) {
                    }
                    i++;
                  });
                }
              }
            });
      };
      $scope.delete = function (user) {
        $http.delete('api/auth/users/' + user.id + '/', user).then(
            function (response) {
              user.$edit = false;
              var obj = _.find($scope.selection, {id: user.id});
              if (angular.isDefined(obj)) {
                $scope.selection = _.without($scope.selection, obj);
              }
              obj = _.find($scope.users, {id: user.id});
              if (angular.isDefined(obj)) {
                $scope.users.splice(_.indexOf($scope.users, obj), 1);
                //$scope.users = _.without($scope.users, obj);
                $scope.checkboxes.items[user.id] = false;
                $scope.updateTotals($scope.tableParams.total() - 1);
                if ($scope.users.length === 0 || $scope.selection.length === 0) {
                  $scope.reload();
                }
              }
            }, function (response) {
              console.error(response);
            });
      };
      $scope.action = function (action) {
        if (action === $scope.actions.delete) {
          angular.forEach($scope.selection, function (user) {
            $scope.delete(user);
          });
        }
      };
      $scope.addToGroup = function (user) {
        if (angular.isDefined(user.toAddName))
          user.toAdd = _.find(user.editGroups, {name: user.toAddName});
        if (!angular.isDefined(user.toAdd))
          return;
        var toAdd = user.toAdd;
        angular.extend(toAdd, {pending: true, added: false});
        $http.post('api/auth/users/' + user.id + '/groups/', {name: toAdd.name}).then(
            function (response) {
              //user.$edit = false;
              if (response.status === 201) {
                toAdd.pending = false;
                toAdd.added = true;
                user.editGroups = removeFromArray(user.editGroups, toAdd, 'id');
                user.groupsNames = _.map(user.editGroups, 'name');
                //user.selectedGroupsNames = _.map(user.groups, 'name');
              }
            }, function (response) {
              console.error(response);
            });
        user.groups.push(toAdd);
      };
      $scope.removeFromGroup = function (user, group) {
        if (!angular.isDefined(group))
          return;
        var toRemove = group;
        var obj = {};
        angular.copy(toRemove, obj);
        angular.extend(toRemove, {pending: true, removed: false});
        $http.delete('api/auth/users/' + user.id + '/groups/' + toRemove.id + '/').then(
            function (response) {
              if (response.status === 204) {
                toRemove.pending = false;
                toRemove.removed = true;
                user.editGroups.push(obj);
                user.groupsNames = _.map(user.editGroups, 'name');
                //user.selectedGroupsNames = _.map(user.groups, 'name');
              }
            }, function (response) {
              console.error(response);
            });
      };
      $scope.changeAllSelection = function (value) {
        $scope.checkboxes.checked = value;
        if (value) {
          angular.forEach($scope.users, function (item) {
            if (angular.isDefined(item.id)) {
              $scope.checkboxes.items[item.id] = value;
            }
          });
          $scope.selection = $scope.users;
        } else {
          $scope.checkboxes.items = {};
          $scope.selection = [];
        }
      };
      // watch for check all checkbox
      $scope.$watch('checkboxes.checked', function (value) {
        $scope.changeAllSelection(value);
      });
      // watch for data checkboxes
      $scope.$watch('checkboxes.items', function (values) {
        if (!$scope.users) {
          return;
        }
        var checked = 0, unchecked = 0;
        var total = $scope.users.length;
        angular.forEach($scope.users, function (item) {
          if ($scope.checkboxes.items[item.id]) {
            checked++;
          } else {
            unchecked++;
          }
        });
        if (total === 0) {
          $scope.checkboxes.checked = false;
        } else if ((unchecked === 0) || (checked === 0)) {
          $scope.checkboxes.checked = (checked === total);
        }
        // grayed checkbox
        angular.element(document.getElementById("select_all")).prop("indeterminate", (checked !== 0 && unchecked !== 0));
      }, true);
      // watch for new search value after 750 ms, so that the table can be reloaded
      // var promiseTimeoutSearch;
      $scope.$watch('search', function (value) {
        // $timeout.cancel(promiseTimeoutSearch);
        // promiseTimeoutSearch = $timeout(function () {
        $scope.tableParams.reload();
        // }, 750);
      });
      // watch if table is loading, if so only show loading animations if it takes more than 1000 ms
      // var promiseTimeoutLoading;
      // $scope.$watch('tableParams.settings().$loading', function (value) {
      //   $timeout.cancel(promiseTimeoutLoading);
      //   if (value) {
      //     promiseTimeoutLoading = $timeout(function () {
      //       $scope.loading = true;
      //     }, 1000);
      //   } else {
      //     $scope.loading = false;
      //   }
      // });
    }]);
  App.controller('AdminUsersNewController', ['$scope', '$http', 'ngDialog',
    function ($scope, $http: ng.IHttpService, ngDialog: ng.dialog.IDialogService) {
      // Default values sent to API
      $scope.data = {
        role: 'user',
        api_key: '',
        phone_number: '00',
        home_state: 'app.devices.dashboard',
        full_name: ''
      };

      $scope.save = function (data) {
        $scope.data.username = _.trim($scope.data.username);
        $scope.data.email = _.trim($scope.data.email);
        if (_.isEmpty(_.trim($scope.data.full_name))) {
          $scope.data.full_name = $scope.data.username;
        } else {
          $scope.data.full_name = _.trim($scope.data.full_name);
        }
        $http.post('api/auth/users/', $scope.data).then(
            function (response) {
              if (response.status === 201) {
                // @ts-ignore
                ngDialog.close();
              }
            }, function (response) {
              console.error(response);
              if (response.status === 400 && response.data) {
                for (var key in response.data) {
                  var specificField = $('#id-' + key).parsley();
                  var i = 0;
                  angular.forEach(response.data[key], function (error) {
                    try {
                      specificField.addError('error-' + i, {message: error});
                      // window.ParsleyUI.addError(specificField, 'error-' + i, error);
                    } catch (e) {
                    }
                    i++;
                  });
                }
              }
            });
      };
    }]);
}