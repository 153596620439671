namespace wg {
  interface IScope extends ng.IScope {
    changeSelection(item: IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm): void;

    changeAllSelection(value: boolean): void;

    list_items: (IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm)[];
    selection: (IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm)[];
    checkboxes: {
      checked: boolean,
      items: { [id: number]: boolean }
    };
  }

  App.controller('ManageDataTableController', ['$rootScope', '$scope', '$state', '$http', '$filter', '$timeout', 'ngTableParams', 'ngDialog', 'AuthService',
    function ($rootScope: IRootScope, $scope: IScope, $state: _IState, $http: ng.IHttpService, $filter: ng.IFilterService, $timeout: ng.ITimeoutService, ngTableParams: NgTable.ITableParamsConstructor<IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm>, ngDialog: angular.dialog.IDialogService, AuthService: IAuthService) {

      //                                      ---     VARIABLES
      // entity_type = $state.current.url.slice(1);
      // @ts-ignore
      let entity_type = $state.current?.url?.match("\/?([^\?\/&]+).*")[1];
      if (_.isEmpty(entity_type)) {
        console.error("Error, trying to access ", $state.current.url);
      }
      let entity_type_singular;
      // entity_type_singular = toSingular[entity_type] || 'unit';

      let table_sorting: { [column: string]: 'asc' | 'desc' } = {name: 'asc'}

      if (entity_type.startsWith('device')) {
        entity_type_singular = 'device';

      } else if (entity_type.startsWith('smartbox')) {
        entity_type_singular = 'smartbox';

      } else if (entity_type.startsWith('process')) {
        entity_type_singular = 'process';
        table_sorting = {
          active: 'desc',   // Active first
          created_at: 'asc',
        }

      } else if (entity_type.startsWith('batch')) {
        entity_type_singular = 'batch';

      } else if (entity_type.startsWith('place')) {
        entity_type_singular = 'place';

      } else if (entity_type.startsWith('alarm')) {
        entity_type_singular = 'alarm';

      } else if (entity_type.startsWith('license')) {
        entity_type_singular = 'license';

      } else {
        entity_type_singular = 'unit';
      }

      $scope.loading = false;
      //                  --- SELECTED
      let animateSelected: angular.IPromise<any>;
      $scope.see = {
        item: null,
        is: function () {
          return !_.isNil($scope.see.item);
        },
        select: function (item) {
          if ($scope.delete_success === true)
            $scope.delete_success = null
          let _url_params = {};
          _url_params[entity_type_singular] = item.id;
          AuthService.update_url(_url_params, false, false, false);

          $scope.see.item = null;

          if (angular.isDefined(animateSelected)) {
            $timeout.cancel(animateSelected);
          }
          animateSelected = $timeout(function () {
            $scope.see.item = item;
          }, 1);
          $('html, body').animate({scrollTop: $(document).height()}, 800, 'swing');
        },
        clear: function () {
          $scope.see.item = null;
          let _url_params = {};
          _url_params[entity_type_singular] = null;
          AuthService.update_url(_url_params, false, false, false);
        }
      };

      $scope.counts = {
        ON: 0,
        OFF: 0,
        TOTAL: 0,
      };

      // --- Multi selection and actions
      // Selected entries
      $scope.selection = [];
      $scope.checkboxes = {
        checked: false, // all selected
        items: {},  // checked items
      };

      $scope.changeSelection = function (item) {
        // remove or add an item to selection array, based on item.id
        let obj = _.find($scope.selection, {id: item.id});
        if (!$scope.checkboxes.items[item.id]) {
          // De-selected
          if (obj)
            $scope.selection = _.without($scope.selection, item);
        } else {
          if (!obj) { // @ts-ignore
            $scope.selection.push(item);
          }
        }

        if (!$scope.list_items) {
          return;
        }
        var checked = 0, unchecked = 0;
        var total = $scope.list_items.length;
        angular.forEach($scope.list_items, function (item) {
          if ($scope.checkboxes.items[item.id]) {
            checked++;
          } else {
            unchecked++;
          }
        });

        // Select_all logic
        if (total > 0 && checked === total) {
          $scope.checkboxes.checked = true;
        } else {
          $scope.checkboxes.checked = false;
        }
      };

      $scope.changeAllSelection = function (value) {
        if (WG_debug) console.log("changeAllSelection", value);
        $scope.checkboxes.checked = value;
        //v// Select or de-select all items
        if (value) {
          _.forEach($scope.list_items, function (item: IPlace | IUnit | IDevice | IProcess | IBatch) {
            if (!_.isNil(item?.id)) {
              $scope.checkboxes.items[item.id] = value;
            }
          });
          $scope.selection = $scope.list_items;
        } else {
          $scope.checkboxes.items = {};
          $scope.selection = [];
        }
      };

      // $scope.actions = { action: null, delete: 'DELETE'};
      // $scope.action = function(action) {
      //     if (action === $scope.actions.delete) {
      //         angular.forEach($scope.selection, function(user) {
      //             $scope.delete(user);
      //         });
      //     }
      // };

      //  ---  Multi-actions
      $scope.batch = {
        feedback: null,
        states: {success: 'success', error: 'error', loading: 'loading'},
        action: null, // Selected action
        actions: {delete: 'delete', stop: 'stop', activate: 'activate', deactivate: 'deactivate'},
        items: [], // Holds Items state during processing
        do: function () {
          console.log("Multi action ", this.action);
          let self = this; // => $scope.batch

          self.items = emptyOrCreateArray(self.items);
          //    Make array for multi feedback
          _.forEach($scope.selection, function (entry, key) {
            self.items[key] = null;
          });
          this.feedback = this.states.loading;

          //  ACTIONs

          if (self.action === self.actions.stop) {
            this.stop()
          } else if (self.action === self.actions.delete) {
            this.delete()
          } else if (self.action === self.actions.activate) {
            this.activate()
          } else if (self.action === self.actions.deactivate) {
            this.activate(false)
          }
        },
        clear: function () {
          this.action = null;
          this.feedback = null;
          this.items = []
        },
        delete: function () {
          console.log("Batch Delete");
          this.feedback = this.states.loading;
          //  *Batch delete
          _.forEach($scope.selection, function (entry: IPlace | IUnit | IDevice | IProcess | IBatch, batchKey) {
                $scope.delete(entry, batchKey);
                if ($scope.see.item !== null && $scope.see.item.id === entry.id)
                  $scope.see.clear();
              }
          );
        },
        activate: function (active = true) {
          console.log("Batch activate: ", active);
          this.feedback = this.states.loading;
          //  *Batch activate
          _.forEach($scope.selection, function (entry: IPlace | IUnit | IDevice | IProcess | IBatch | IAlarm, batchKey) {
                if (entity_type === 'alarms') {
                  $rootScope.WGAlarms.activate(entry.id, active, (response) => {
                    $scope.reload();
                    updatedBatchFeedback(batchKey, response);
                  });
                }
                if ($scope.see.item !== null && $scope.see.item.id === entry.id)
                  $scope.see.clear();
              }
          );
        },
        stop: function () {
          console.log("Batch Units Stop");
          if (entity_type_singular != 'process' || editElemModalIsOpen) {
            console.log("batch stop modal already open");
            return;
          }
          let stopIts = [];
          //  *create pass array for dialog if action===stop
          _.forEach($scope.selection, function (process: IProcess, key) {
            if (process.active)
              stopIts.push({
                id: process.id,
                name: process.name,
                started_at: process.started_at,
                active: process.active,
                batch_key: key,
              });
          });
          editElemModalIsOpen = true;
          //  *Batch stop with dialog
          let manyStop = ngDialog.open({
            template: 'app/views/modals/stop_many.html',
            controller: 'SeeRunStopController',
            className: 'ngdialog-theme-default',
            data: {
              isMany: true,
              procs: stopIts
            }
          });
          manyStop.closePromise.then(function (data) {
            console.log("Process Stop dialog closed.",
                "data:", data);
            $scope.reload(true);
          });
        }
      };
      $scope.$watch('batch.items', function (o, n) {
        console.log("Batch.items changed:", $scope.batch.items, "state:", $scope.batch.feedback);
        if (!_.isEmpty($scope.batch.items)) {
          var k = _.indexOf($scope.batch.items, false);
          if (k > -1) {
            $scope.batch.feedback = $scope.batch.states.error;
          } else {
            $scope.batch.feedback = $scope.batch.states.success;
          }
        }
      }, true);

      //                  --- DATA-TABLE
      $scope.list_items = [];

      $scope.search = {
        all: '',
        do_search: function () {
          console.log("Search:", this.all);
          $scope.tableParams.reload();
        },
      };
      $scope.showingFrom = 0;
      $scope.showingTo = 0;

      $scope.tableParams = new ngTableParams({
        page: 1,            // show first page
        count: 25,          // initial count per page
        sorting: table_sorting,
        filter: {}
      }, {
        total: 0, // length of data
        paginationMaxBlocks: 7, // 7 = first, 3 centered, last
        paginationMinBlocks: 3,
        getData: (params) => {
          $scope.loading = true;
          console.log("Get-TD " + entity_type);
          let search = $scope.search.all;
          let filter = '';
          let obj = params.filter();
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (angular.isDefined(obj[key]) && obj[key] !== '')
                filter += key + '=' + obj[key] + '&';
            }
          }
          let query = '';
          if (params.orderBy() + '' !== '') {
            query += '&ordering=' + params.orderBy();
          }
          if (angular.isDefined(search) && search !== '') {
            query += '&search=' + search;
          } else {
            query += '&page=' + params.page();
          }
          if (filter !== '') {
            filter = filter.substring(0, filter.length - 1);
            query += '&' + filter;
          }
          let _type = entity_type;
          if (entity_type == 'alarms' || entity_type == 'alarm_templates') {
            _type = 'alarm'
          }
          // console.log('Get-TD api/dashboard/'+cena+'/?page=' + params.page() + '&page_size=' + params.count() + query, "AND search:", search);
          return $http.get<IDataResponse<IPlace | IUnit | IDevice | IProcess | IBatch>>('api/dashboard/' + _type + '/?page_size=' + params.count() + query, {}).then(
              function (response) {
                console.log("Got-TD " + entity_type + ". response:", response);

                $scope.list_items = filter_data(response.data.results);

                AuthService.anonymize_entities(entity_type_singular, $scope.list_items);

                $scope.changeAllSelection(false);
                //angular.forEach(data, function(user) {
                //  var obj = _.find($scope.users,  {id: user.id});
                //  if (angular.isDefined(obj)) {
                //    angular.extend(obj, user);
                //    $scope.checkboxes.items[user.id] = false;
                //  } else {
                //    $scope.users.push(user);
                //  }
                //});
                _.forEach($scope.list_items, function (elem) {
                  if (_.toInteger($state?.params?.[entity_type_singular]) == elem.id) {
                    $scope.see.select(elem);
                    return false;
                  }
                });
                // update table params
                $scope.updateTotals(response.data.count);
                $scope.loading = false;
                return $scope.list_items
              }, function (response) {
                $scope.loading = false;
                console.error(response);
              });
          // $scope.getGroups();
        }
      });

      //                              ---     ACTIONS
      var editElemModalIsOpen = false;
      var deleteConfirmIsOpen = false;

      $scope.create_process_element = function () {
        if (editElemModalIsOpen) {
          console.log("attempt to open second edit modal");
          return;
        }
        editElemModalIsOpen = true;

        var element = $scope.see.item;

        //console.log("Create modal for process with",entity_type);
        var dialogTemplate = 'app/views/modals/edit-dialog-process.html';
        var dialogData = {
          process: {},
          result: 'selectedUnitEditProcess',
          mode: 'openConfirm',
          operation: 'create',
          closeByEscape: false,
          closeByDocument: false,
          hasSmtg: false,
          hasWhat: '',
        };

        // object
        if ($scope.see.is()) {
          dialogData.hasSmtg = true;
          dialogData.hasWhat = entity_type_singular;
          dialogData[entity_type_singular] = $scope.see.item;
        }
        //
        // if(entity_type==='batches'){
        //   dialogData.hasWhat= 'batch';
        //   dialogData.batch= element;
        // }else if(entity_type==='units'){
        //   dialogData.hasWhat= 'unit';
        //   dialogData.unit= element;
        // }
        var dialog = ngDialog.open({
          template: dialogTemplate,
          data: dialogData
        });
        dialog.closePromise.then(function (obj) {
          editElemModalIsOpen = false;

          console.log("close_create_process_element_Dialog. obj:", obj);
          $scope.tableParams.reload();
        });
      };


      // Open modal to CREATE/EDIT: Batch // Place // Unit // Alarm
      $scope.edit = function (elem, whatThis, withThis) {
        console.log("OpenEdit", {entity_type: entity_type, whatThis: whatThis, withThis: withThis});

        if (editElemModalIsOpen) {
          console.log("Attempt to open second edit modal");
          return;
        }
        editElemModalIsOpen = true;
        let dialog: { closePromise?: angular.IPromise<any> } = null;

        //  accao
        let accao = angular.isDefined(elem) ? 'edit' : 'create';

        if (entity_type == 'alarms') {
          dialog = $rootScope.modalSetAlarm(elem?.device || elem);
        } else {

          let options = {
            template: 'app/views/modals/edit-dialog-' + entity_type + '.html',
            width: 540,
            data: {
              elemType: entity_type,
              action: accao,
              elem: elem,
              hasSmtg: false,
              hasWhat: '',
              place: null,
            }
          };

          // WITH OBJECT? only for units
          if ($scope.see.is() && $scope.pages === 'units') {
            options.data.hasSmtg = true;
            options.data.hasWhat = entity_type_singular;
            options.data[entity_type_singular] = $scope.see.item;
          }

          // -------------------------------Create Unit in place----------------------------------//
          if (whatThis === 'unit' && withThis === 'place') {
            options = {
              template: 'app/views/modals/edit-dialog-units.html',
              width: 540,
              data: {
                elemType: entity_type,
                action: 'create',
                elem: $scope.see.item,
                hasSmtg: true,
                hasWhat: 'place',
                place: $scope.see.item,
              }
            };
          }

          //  GO!
          dialog = ngDialog.open(options);

        }

        if (!dialog) {
          editElemModalIsOpen = false;
          if (WG_debug) console.warn("ngDialog didn't open?");
          return;
        }

        dialog?.closePromise?.then(function (obj) {
          // console.log("closeElemEditDialog. obj:", obj, "list_items", $scope.list_items);
          // if ((typeof obj.value === "object") && (obj.value !== null)) {
          //   $scope.see.item = obj.value.item_data;
          //   let d = _.findIndex($scope.list_items as (IDevice | IPlace | IUnit | IProcess | IBatch | IAlarm)[], {id: obj.value.item_data.id});
          //   // let d = _.findIndex($scope.list_items, function (x: IPlace | IUnit | IDevice | IProcess | IBatch) {
          //   //   return x.id === obj.value.item_data.id;
          //   // });
          //   if (d < 0) {
          //     $scope.list_items.push(obj.value.item_data);
          //     $scope.list_items.sort(order_names);
          //   } else {
          //     $scope.list_items[d] = obj.value.item_data;
          //   }
          // }
          // $scope.reload();
        }, function (reason) {
        }).finally(function () {
          editElemModalIsOpen = false;
          $scope.reload();
        });
      };


      $scope.delete = function (elemento, batchKey) {
        $scope.loading = true;
        // if (batchKey >= 0) {
        //   deleteThing(elemento, batchKey);
        //   return
        // }

        console.log("DeleteConfirm ", entity_type, ", element: ", elemento);
        if (!deleteConfirmIsOpen) {
          deleteConfirmIsOpen = true;
        } else {
          $scope.loading = false;
          return
        }
        ngDialog.openConfirm({
          template: 'app/views/modals/confirm-danger.html',
          className: 'ngdialog-theme-default',
          data: {
            what: entity_type,
            which: elemento.name
          }
        }).then(function (data) {
          console.log('Delete Confirmed. data:', data);
          deleteThing(elemento, batchKey);
          deleteConfirmIsOpen = false;
        }, function (reason) {
          deleteConfirmIsOpen = false;
          $scope.loading = false;
          console.log('DELETE Modal promise rejected. Reason: ', reason);
        })
      };

      //                              ---     FUNCTIONS

      $scope.delete_success = null;
      $scope.del_suc_null = function () {
        $scope.delete_success = null
      };

      function updatedBatchFeedback(batchKey, result: '' | 'success' | 'error') {
        if (batchKey >= 0) {
          $scope.batch.items[batchKey] = (result === 'success');
        } else {
          $scope.delete_success = (result === 'success');
          $scope.see.clear();
        }
        $scope.loading = false;
        if (result === 'error') {
          console.error(result);
        }
      }

      function deleteThing(element, batchKey) {
        if (!element || element.id < 0) {
          return;
        }
        console.log('Delete Confirmed');
        if (entity_type === 'places') {
          $rootScope.WGPlaces.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          });
        } else if (entity_type === 'units') {
          $rootScope.WGUnits.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          });
        } else if (entity_type === 'devices') {
          $rootScope.WGDevices.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          });
        } else if (entity_type === 'processes') {
          $rootScope.WGProcesses.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          });
        } else if (entity_type === 'batches') {
          $rootScope.WGBatches.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          });
        } else if (entity_type === 'alarms') {
          $rootScope.WGAlarms.delete(element.id, (response) => {
            $scope.reload();
            updatedBatchFeedback(batchKey, response);
          });
        } else {
          console.log("DELETE: entity_type not recognized");
        }


        // $http.delete('api/dashboard/' + entity_type + '/' + element.id + '/')
        //     .then(function (response) {
        //       $rootScope.WGUnits.changed = true;
        //       $rootScope.WGProcesses.changed = true;
        //       $rootScope.WGDevices.changed = true;
        //
        //       //  -Array para definir os items seleccionados
        //       var obj = _.find($scope.selection, {id: element.id});
        //       if (angular.isDefined(obj)) {
        //         $scope.selection = _.without($scope.selection, obj) as IDevice[] | IPlace[] | IUnit[] | IProcess[] | IBatch[];
        //       }
        //
        //       //  -Array para popular a dataTable
        //       obj = _.find($scope.list_items, {id: element.id});
        //       if (angular.isDefined(obj)) {
        //         $scope.list_items.splice(_.indexOf($scope.list_items, obj), 1);
        //         //$scope.users = _.without($scope.users, obj);
        //         $scope.checkboxes.items[element.id] = false;
        //         $scope.updateTotals($scope.tableParams.total() - 1);
        //         if ($scope.list_items.length === 0 || $scope.selection.length === 0) {
        //           $scope.reload();
        //         }
        //       }
        //
        //       //  -Batch User Feedback
        //       if (batchKey >= 0) {
        //         $scope.batch.items[batchKey] = true;
        //       } else {
        //         $scope.delete_success = true;
        //         $scope.see.clear();
        //       }
        //       $scope.loading = false;
        //     }, function (response) {
        //       //  -Batch User Feedback
        //       if (batchKey >= 0) {
        //         $scope.batch.items[batchKey] = false;
        //       } else {
        //         $scope.delete_success = false;
        //       }
        //       $scope.loading = false;
        //       console.error(response);
        //     });
      }

      //                              --- FUNCTIONS
      $scope.updateTotals = function (total) {
        $scope.tableParams.total(total);
        $scope.showingFrom = ($scope.tableParams.page() - 1) * $scope.tableParams.count() + 1;
        if ($scope.showingFrom > $scope.list_items.length)
          $scope.showingFrom = $scope.list_items.length;
        $scope.showingTo = $scope.showingFrom - 1 + $scope.tableParams.count();
        if ($scope.showingTo > $scope.list_items.length)
          $scope.showingTo = $scope.list_items.length;
        if ($scope.showingTo > $scope.tableParams.total())
          $scope.showingTo = $scope.tableParams.total();
      };

      $scope.reload = function () {
        // $state.reload();
        $rootScope.$broadcast('adminReload');
      };

      function filter_data(data) {
        var dataset = data;
        // var dataset = angular.copy(data);
        _.forEach(dataset, function (elem, i) {
          if (_.isNil(elem)) { // sanity check
            return;
          }
          if (entity_type === 'alarms') {
            elem.actions = parseData(elem.actions);
          }

          // if(entity_type==='batches' || entity_type==='processes'){
          //   if(elem.started_at!==null) {
          //     elem.started_at = new Date(elem.started_at).getTime();
          //   }
          //   if(elem.ended_at!==null) {
          //     elem.ended_at = new Date(elem.ended_at).getTime();
          //   }
          // }

          // filter inactive(finished) processes from the returned batches
          // if (entity_type === 'batches') {
          //   if (!_.isEmpty(elem.processes)) {
          //     elem.processes = _.filter(elem.processes, {'active': true});
          //   }
          // }
        });
        return dataset
      }

      $scope.$on('adminReload', function (event, args) {
        console.log('adminReload', 'admin-elements');
        $scope.tableParams.reload();
      });

      // watch if table is loading,
      // if so only show loading animations
      // if it takes more than 1000 ms
      var promiseTimeoutLoading: angular.IPromise<any>;
      $scope.$watch('tableParams.settings().$loading', function (value) {
        // console.log("$watch('tableParams.settings().$loading'", $scope);
        $timeout.cancel(promiseTimeoutLoading);
        if (value) {
          promiseTimeoutLoading = $timeout(function () {
            $scope.loading = true;
          }, 1000);
        } else {
          $scope.loading = false;
        }
      });

      //  -DEV
      $scope.spitData = function () {
        console.log("spitData. $scope", $scope, "selected=", $scope.see.is());
      };
    }])
  ;
}