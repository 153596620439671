namespace wg {
  export class WgAlarmRule {

    public TASK_RULE_TYPES = wg.TASK_RULE_TYPES;
    public rule: IAlarmRule;
    public stage: IWinemakingStage;
    public task: ITask;
    public errors: Map<IAlarmRule, []>;
    public showErrors: boolean;

    public sensors: ISensor[] = [];

    public scope: any;

    static $inject = ['$scope', '$rootScope', 'DataUtils', '$translate'];

    public constructor(private $scope: any, private $rootScope, private DataUtils: DataUtils, private $translate: ng.translate.ITranslateService) {
      if (WG_debug) console.log('WgAlarmRule constructor');
      this.scope = $scope;

      this.$scope.$watch('ctrl.rule', (newVal, oldVal) => {
        this.checkForErrors();
      }, true);

      //listen for emit showErrors
      this.$rootScope.$on('showErrors', () => {
        this.showErrors = true;
      });

      this.$rootScope.$on('checkErrors', () => {
        this.checkForErrors();
      });

      // (window as any).rules = (window as any).rules || [];
      // (window as any).rules.push(this.rule);

      // Done poreviously on $http.get(Full-tree)
      // // Convert the parameter to an object
      // if (this.rule.parameter && (_.isNumber(this.rule.parameter) || _.isString(this.rule.parameter))) {
      //   let _sensor = _.find($rootScope.WGSensors.sensors, {id: this.rule.parameter});
      //   if (!_sensor) {
      //     _sensor = _.find($rootScope.WGSensors.sensors, {internal_name: this.rule.parameter});
      //   }
      //   if (_sensor) {
      //     this.rule.parameter = _sensor;
      //   }
      // }

      // For now have a list of predefined valid sensors
      this.sensors.push($rootScope.WGSensors.sensors_name['TEMP']);
      this.sensors.push($rootScope.WGSensors.sensors_name['LLV_TOF_TREAT_value']);
      this.sensors.push($rootScope.WGSensors.sensors_name['LLVA_LAT_TREAT_value']);
      this.sensors.push($rootScope.WGSensors.sensors_name['VOLUME_TREAT']);
      this.sensors.push($rootScope.WGSensors.sensors_name['QL_TREAT_LDENSA_massDensity']);
      this.sensors.push($rootScope.WGSensors.sensors_name['AI_LDENSA_FermentationKinetic24h']);
      this.sensors.push($rootScope.WGSensors.sensors_name['PRESSURE_TREAT']);
      this.sensors.push($rootScope.WGSensors.sensors_name['HUM']);
      this.sensors.push($rootScope.WGSensors.sensors_name['CO2']);
      this.sensors.push($rootScope.WGSensors.sensors_name['PH']);

      // remove nulls
      this.sensors = _.remove(this.sensors, null);

      // TODO to JR: get sensors list from $rootScope.WGSensors
      // _.forEach($rootScope.WGSensors.sensors_name, (_sensor, _internal_name) => {
      //   if (!_sensor || !_internal_name) {
      //     return;
      //   }
      //   if(_sensor.configs?.accessLevel != "user") {
      //     return
      //   }
      //   if (!DataUtils.canUserAccessSensor(_internal_name)) {
      //     return;
      //   }
      //   if (_.find(this.sensors, {internal_name: _internal_name})) {
      //     if(WG_debug) console.log('WgAlarmRule, adding sensor already in list', _internal_name);
      //     return;
      //   }
      //   this.sensors.push(_sensor);
      // })
    }

    $onInit() {
      if (this.rule.delay == undefined) {
        this.rule.delay = 0;
      }
    }

    public deleteRule(rule: IAlarmRule) {
      if (rule.id) {
        rule.markedForDeletion = !rule.markedForDeletion;

      } else {
        _.remove(this.task.rules, rule);
      }
      //clear myErrors
      const myErrors: {} = this.errors?.get(rule);
      for (let key in myErrors) {
        delete myErrors[key];
      }
    }


    public onDelayChange(rule) {
      this.checkForErrors()
    }

    public checkForErrors() {
      if (WG_debug) console.log('WgAlarmRule checkForErrors', this.rule);
      if (!this.errors) return;

      if (!(this.errors.get(this.rule) instanceof Object)) this.errors.set(this.rule, {} as any);
      const myErrors: {} = this.errors.get(this.rule);

      //clear myErrors
      for (let key in myErrors) {
        delete myErrors[key];
      }


      if (_.isNil(this.rule.type_id)) {
        myErrors["type"] = this.$translate.instant('app.winemaking.errors.MISSING_PRECONDITION');
      }


      switch (this.rule.type_id) {

        case TASK_RULE_TYPES.parameter_reached.id:
          //parameter
          if (!this.rule.parameter) {
            myErrors["parameter"] = this.$translate.instant('app.winemaking.errors.MISSING_PARAMETER');
            return;
          }

          //logical
          if (!this.rule.logical) {
            myErrors["logical"] = this.$translate.instant('app.winemaking.errors.MISSING_OPERATOR');
            return;
          }

          //value
          if (_.isNil(this.rule.value)) {
            myErrors["value"] = this.$translate.instant('app.winemaking.errors.MISSING_VALUE');
            return;
          }

          break

        case TASK_RULE_TYPES.other_task_finished.id:
          if (!this.rule.previousTask) {
            myErrors["task"] = this.$translate.instant('app.winemaking.errors.MISSING_DEPENDENCY');
            return;
          }
          if (this.rule.previousTask == this.task.id) {
            myErrors["same_task"] = this.$translate.instant('app.winemaking.errors.INVALID_DEPENDENCY');
            return;
          }

          break;

        case TASK_RULE_TYPES.elapsed_time_since_stage_start.id:
          if (_.isNil(this.rule.delay)) {
            myErrors["delay"] = this.$translate.instant('app.winemaking.errors.MISSING_DELAY');
            return;
            // } else if (this.rule.delay == 0) {
            //     myErrors["delay"] = "Please select a delay greater than 0";
          }
          break;
      }


    }

    public getErrors() {
      return this.errors.get(this.rule);
    }

    public getNumberOfErrors() {
      return Object.keys(this.errors.get(this.rule)).length || 0;
    }

    public getErrorsText(): string[] {
      if (!this.rule) return

      const errorTexts: [] = [];
      //use lodash to iterate over this.errors.get(this.rule))
      for (let key in this.errors.get(this.rule)) {
        errorTexts.push(this.errors.get(this.rule)[key]);
      }
      return errorTexts
    }


    public getRuleText(rule: IAlarmRule) {
      const ruleType = _.find(TASK_RULE_TYPES, {id: rule.type_id});
      return ruleType?.sref;
    }

    public getTaskById(id: number) {
      return this.stage?.tasks?.find(task => task.id == id);
    }


  }

  App.component('wgAlarmRule', {
    templateUrl: 'app/views/partials/wg-alarm-rule.html',
    controller: WgAlarmRule,
    controllerAs: 'ctrl',
    bindings: {
      rule: '=',
      stage: '<',
      task: '=',
      errors: '=',
      // showErrors: '=?',
    }
  })
}