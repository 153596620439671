/**=========================================================
 * Module: wg-units.js
 * Data-Access-Layer for Dashboard Data (Places, Units, etc etc)
 =========================================================*/

namespace wg {

  export class WGApiPlaces implements IWGApiEntity<IPlace> {
    private apiData: WGApiData;
    private $rootScope: wg.IRootScope;
    private $translate: ng.translate.ITranslateService;
    private $http: ng.IHttpService;
    private AuthService: wg.IAuthService;
    private DataUtils: wg.DataUtils;

    ready: boolean = false;
    changed: boolean = true;
    changed_locally: boolean = false;
    loading: boolean = false;
    processing: boolean = false;
    retries: number = 0;

    // Method. Timer used for repeating update
    timer?: NodeJS.Timeout;

    places: IPlace[];
    places_id: { [id: number]: IPlace };


    constructor(apiData: wg.WGApiData, $rootScope: wg.IRootScope, $translate: ng.translate.ITranslateService, $http: ng.IHttpService, AuthService: wg.IAuthService, DataUtils: wg.DataUtils) {
      this.apiData = apiData;
      this.$rootScope = $rootScope;
      this.$translate = $translate;
      this.$http = $http;
      this.AuthService = AuthService;
      this.DataUtils = DataUtils;
    }

    reset() { // Places
      this.places = emptyOrCreateArray(this.places);
      this.places_id = emptyOrCreateDict(this.places_id);
      this.ready = false;
      this.changed = true;
      this.changed_locally = false;
      this.loading = false;
      this.processing = false;
      this.retries = 0;

      this.timer = null;
    }

    // Places have changed. Redo it's dependencies and dependents
    process_data() { // Places
      if (this.loading) {
        return;
      }
      this.processing = true;
      this.changed_locally = false;
      this.ready = false;

      // if (WG_debug) console.time("WGPlaces-process_data");

      // Reconnect units and places
      this.apiData.link_units_and_places(); // This call can change visible units. Refresh filters

      // Re-run other functions dependent on these connections


      // if (WG_debug) console.timeEnd("WGPlaces-process_data");
      this.processing = false;
      this.ready = true

      this.apiData.update_ready_status();
      this.DataUtils.global_apply_schedule("data-changed");
      this.$rootScope.$broadcast('places_updated');
    }

    // Processes passed object as Place
    parse(entry: IPlace) {
      if (!entry.id) {
        console.error("Wrong Place object:", entry);
        return null;
      }
      let _place = _.pick(entry, ['id', 'name', 'name_sref', 'description', 'dynamic_fields', 'owner.id', 'owner.username', 'units']) as IPlace;

      // Add required fields not arriving from API
      this.DataUtils.normalize_places_info(_place);

      this.AuthService.anonymize_entity("Place", _place);

      return _place;
    }

    // Parses an object as Place and merges onto existing this.WGPlaces
    merge_entry(entry: IPlace, process_afterwards = false): IPlace {
      let _place = this.parse(entry);

      if (!_place || !_place.id || _place.type !== 'place') {
        console.warn("Merging wrong Place!", _place);
        return null;
      }

      if (this.places_id[_place.id]) {
        // Already exists. Just update one reference's data
        // if (WG_debug) {
        //   // Print differences that arrived from server
        //   let diffs = _.reduce(_place, (result, value, key) => {
        //     if (['units', 'counts'].includes(key))
        //       return result;
        //     return _.isEqual(value, this.places_id[_place.id][key]) ? result : result.concat(key);
        //   }, []);
        //
        //   if (diffs.length)
        //     console.info("Place Changed: ", {diffs: diffs, from: _.clone(this.places_id[_place.id]), to: _.clone(_place)});
        // }

        _.assign(this.places_id[_place.id], _place);
      } else {
        this.places.push(_place);
        this.places_id[_place.id] = _place;
      }
      if (process_afterwards) {
        this.process_data();
      } else {
        this.changed_locally = true;
      }
      return this.places_id[_place.id];
    }

    update() { // Places
      if (this.loading) {
        return;
      }

      // if (WG_debug) console.time("WGPlaces-update");

      this.ready = false;
      this.changed = false;
      this.loading = true;
      this.apiData.AllReady = false;

      // Used to detect removals
      let _place_ids = [];

      let get_and_merge = (page = 1, force_last = false) => {
        this.apiData.last_data_request = Date.now();
        if (WG_debug) console.info('WGApiData, getting Places, page:', page);
        // When no response arrives in 1 min,
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          console.error("Getting Places timedout!!! Retrying")
          this.loading = false;
          this.update();
        }, 65000);


        this.$http.get<IDataResponse<IPlace>>('api/dashboard/places/?page_size=1000&page=' + page, {timeout: 45000}).then(
            (response) => { // onSuccess
              // if (WG_debug) console.timeLog("WGPlaces-update");

              clearTimeout(this.timer);


              // Merge with the existing places list, don't replace it.
              _.forEach(response.data.results, (_entry) => {
                if (!_entry.id) return;

                _place_ids.push(_entry.id);

                this.merge_entry(_entry);
              });

              // If response.data arrived with "next", request next page
              if (!force_last && response.data.next) {
                let _page = response.data.next?.split('page=')[1]?.split('&')[0];
                if (_page == page + '') {
                  if (WG_debug) console.warn("Getting Places page error!", response.data.next, page);
                  force_last = true;
                }
                get_and_merge(page + 1, force_last);
                return;
              }

              this.retries = 0;

              // Remove deleted entries
              _.forEach(this.places_id, (_place, _id) => {
                if (!_place) {
                  return;
                }
                if (_place_ids.includes(_place.id)) {
                  return;
                }
                if (_place.id < 0) {
                  // Don't remove virtual-places
                  return;
                }

                console.info("Place Changed. Deleted: ", {del: _.clone(_place)});
                _.remove(this.places, {id: _place.id});
                delete this.places_id[_place.id];
              });

              console.log("Updated Places: ", _.size(this.places));

              this.loading = false;

              // if (WG_debug) console.timeEnd("WGPlaces-update");
              this.process_data();
              return;
            },
            (reason) => { // onError
              this.ready = true;
              this.changed = true;
              this.loading = false;
              console.error('Failed getting places. Retrying', this.retries, reason);

              clearTimeout(this.timer);
              if (this.retries++ <= 3) {
                setTimeout(() => {
                  this.update();
                }, 2000);
              }

              // if (WG_debug) console.timeEnd("WGPlaces-update");
              return;
            }
        );
      }
      get_and_merge(1);

      return;
    }

    update_singular(id): void { // Places
      if (!id || id < 0) {
        console.warn("Updating wrong Place!", id);
        return;
      }

      this.$http.get<IPlace>('api/dashboard/places/' + id + '/', {timeout: 45000}).then(
          (response) => { // onSuccess
            this.merge_entry(response.data, true);
            console.log("Updated Place", id);
            return;
          },
          (reason) => { // onError
            console.error("Error updating Place: ", reason);
            return;
          });
      return;
    }

    delete(id: number, callback) { // Places
      let _place = this.places_id[id];
      if (!_place) {
        console.warn("Place doesn't exist to be deleted:", id);
        callback?.('error');
        return;
      }

      this.changed_locally = true;
      if (!_.isEmpty(_place.units))
        this.apiData.WGUnits.changed_locally = true;

      this.apiData.process_data_soon(3000);
      this.$http.delete('api/dashboard/places/' + _place.id + '/').then(
          (response) => {
            if (response.status >= 200 && response.status < 300) {
              console.log("WGPlace Delete SUCCESS");
              this.delete_local(id);
              callback?.('success');
            } else {
              console.warn("WGPlace Delete Failed!", response);
              callback?.('error');
            }
          }, (response) => {
            console.error("WGPlace Delete Failed", response);
            callback?.('error');
          }
      ).finally(() => {
        this.apiData.process_data_soon();
      });
    }

    delete_local(id: number) { // Places
      let _entry = this.places_id[id];

      if (!_entry) {
        if (WG_debug) console.warn("No Place detected with id:", id);
        return
      }

      if (WG_debug) console.info("Deleting Place locally", _entry);

      // Delete from the main data-structure
      delete this.places_id[id];
      _.remove(this.places, {id: id});

      this.changed_locally = true;
      this.apiData.process_data_soon(200, true);
    }
  }
}

