/**=========================================================
 * Module: data-utils.js
 * Utility library to perform data operations across the dashboard
 =========================================================*/

namespace wg {

  // export interface IDataUtils {
  //   global_apply_schedule: (topic?: string, timeout?: number) => void;
  //   update_device_status_soon: (device: IDevice, timeout?: number, replace?: boolean) => void;
  // }


  export class DataUtils {
    static $inject = ['$rootScope', 'AuthService', '$translate', '$timeout'];

    constructor(private $rootScope: IRootScope, private AuthService: IAuthService, private $translate: ng.translate.ITranslateService, private $timeout: ng.ITimeoutService) {
    }

    // Slack to allow time drift between Browser and Smartbox
    public ALLOWED_TIME_DRIFT = 40 * 1000;
    // Max amount of time between a Ping is sent and a Pong received. Keep in mind PING-timestamp is Browser-time, while PONG-timestamp is Smartbox-time
    public PING_TIMEOUT = 12 * 1000;

    /**
     * Verifies if current user has permission to access a given parameter.
     * If "view_as_client" is set, it checks the permissions of the selected instead of the logged user.
     * Takes into account access_exception configs.
     * @param internal_name - sensor.internal_name to check
     * @returns {boolean}
     */
    public canUserAccessSensor(internal_name) {
      // console.log('canAccess', sens, sens === undefined, typeof sens);
      if (!internal_name) {
        return false;
      }
      if (internal_name == 'MANUAL_Entries') {
        return true;
      }
      if (internal_name.includes("MESHVINES_SIMULATOR_MANUAL") || internal_name.includes("FERMENT_SIMULATOR_MANUAL")) {
        internal_name = _.replace(internal_name, "_MANUAL", "")
      }
      if (!this.$rootScope.WGSensors.sensors_name?.[internal_name]) {
        if (WG_debug) console.log('Sensor not defined in AppData?', internal_name);
        return false;
      }
      let _sensor = this.$rootScope.WGSensors.sensors_name[internal_name];
      if (!_sensor?.configs) {
        // console.log('canAccess', 'no configs');
        return false;
      }
      let target_uuid = this.AuthService.user.uuid;
      if (this.AuthService.clients_view && this.AuthService.view_as_owner.uuid) {
        target_uuid = this.AuthService.view_as_owner.uuid;
      }
      // Check if a permission was manually removed to this user
      if (_sensor.configs.accessDeny?.indexOf(target_uuid) >= 0) {
        // console.log('canAccess', 'accessDeny');
        return false;
      }
      if (!_sensor.configs.accessLevel
          || this.AuthService.canAccess(_sensor.configs.accessLevel)) {
        return true;
      }
      // Check if a permission was manually given to this user
      if (_sensor.configs.accessException?.indexOf(target_uuid) >= 0) {
        // console.log('canAccess', 'accessException');
        return true;
      }
      return false;
    }

    public translate(obj: object | string) {
      return obj?.[this.$translate.use()] || obj?.['en-GB'] || obj;
    }

    /**
     * Links desired/configured card_parameters to a separate structures for easy access.
     * Some related sensors are merged (temps, pressures), others are persistent (Temp on WPs)
     *
     * @param device The device to analyze
     * @param reset Redo all previous calculations. Should not be necessary
     */
    public update_device_card_parameters(device: IDevice, reset: boolean = false) {
      if (_.isEmpty(device?.last_values) || !this.$rootScope.WGSensors.ready) {
        return
      }
      // if (WG_debug) console.log("Update device card parameters");

      if (reset || !device.last_card_values) {
        device.last_card_values = emptyOrCreateDict(device.last_card_values);
      }

      let list_of_sensors = _.cloneDeep(this.$rootScope.cardview_sensors_list);

      if (list_of_sensors.includes('MANUAL_Entries')) {
        _.remove(list_of_sensors, 'MANUAL_Entries');

        // make a list of [internal_name, timestamp] for all entries used in device.last_values[_internal_name]
        // order by timestamp and add internal_name to list_of_sensors
        let manual_entries: [string, number][] = [];
        _.forEach(device.last_values, (_value, _internal_name) => {
          if (this.$rootScope.WGSensors.sensors_name[_internal_name]?.configs?.manual && _value.timestamp) {
            insertSortedByInPlace(manual_entries, [_internal_name, _.round(_value.timestamp / 1000 / 60 / 60 / 24)], [1], false);
          }
        });
        _.forEachRight(manual_entries, function (_entry) {
          list_of_sensors.push(_entry[0]);
        });
        list_of_sensors = _.uniq(list_of_sensors);
      }

      _.forEach(list_of_sensors, (_internal_name) => {
        if (!_internal_name) {
          return;
        }

        let _sensor = this.$rootScope.WGSensors.sensors_name[_internal_name];
        if (!_sensor) {
          return;
        }
        if (!this.canUserAccessSensor(_internal_name)) {
          return;
        }

        // Merge similar sensors
        {
          if (['TEMP', 'QL_TEMP'].includes(_internal_name)) {
            if (_.isFinite(device.last_values['TEMP']?.val_numeric)) {
              device.last_card_values['TEMP'] = device.last_values['TEMP'];
              return;
            }
            if (_.isFinite(device.last_values['QL_TEMP']?.val_numeric)) {
              device.last_card_values['TEMP'] = device.last_values['QL_TEMP'];
              return;
            }
          }

          if (['IN_HUM', 'IN_HUMF'].includes(_internal_name)) {
            if (_.isFinite(device.last_values['IN_HUM']?.val_numeric)) {
              device.last_card_values['IN_HUM'] = device.last_values['IN_HUM'];
              return;
            }
            if (_.isFinite(device.last_values['IN_HUMF']?.val_numeric)) {
              device.last_card_values['IN_HUM'] = device.last_values['IN_HUMF'];
              return;
            }
          }

          if (['PRESSURE_TREAT', 'PRESSA_TREAT'].includes(_internal_name)) {
            if (_.isFinite(device.last_values['PRESSURE_TREAT']?.val_numeric)) {
              device.last_card_values['PRESSURE_TREAT'] = device.last_values['PRESSURE_TREAT'];
              return;
            }
            if (_.isFinite(device.last_values['PRESSA_TREAT']?.val_numeric)) {
              device.last_card_values['PRESSURE_TREAT'] = device.last_values['PRESSA_TREAT'];
              return;
            }
          }
        }

        if (device.last_values[_internal_name]) {
          if (_sensor.configs.manual && device.last_values[_internal_name].status == 'FAIL') {
            // if (WG_debug) console.info("last_card_values, manual entry with Fail, don't show on card");
            delete device.last_card_values[_internal_name];
          } else if (device.last_card_values[_internal_name] === device.last_values[_internal_name]) {
            // Already current
          } else {
            // New last_value received
            device.last_card_values[_internal_name] = device.last_values[_internal_name];
            // if (WG_debug) console.info("last_card_values added", _internal_name, _sensor.configs.manual, device.last_values[_internal_name].status);
          }
          return;
        }


        // Force show some parameters in specific devices, showing --/NA if no data available
        {
          if (_internal_name === 'QL_TREAT_LDENSA_massDensity'
              && !device.last_card_values[_internal_name]) {
            if ((device.model.startsWith("wp1")
                || device.model.startsWith("bp"))) {
              device.last_card_values[_internal_name] = {
                sensor: _sensor, // deprecate
                internal_name: _internal_name,
                timestamp: undefined,
                val: undefined,
                val_numeric: undefined,
                status: undefined,
              }
              return;
            }
          }

          if (['QL_TEMP', 'TEMP'].includes(_internal_name)
              && !device.last_card_values['TEMP']) {
            if ((device.model.startsWith("wp1")
                || device.model.startsWith("bp"))) {
              // Should show 1 temperature, but not yet received.
              device.last_card_values['TEMP'] = {
                sensor: _sensor, // deprecate
                internal_name: 'TEMP',
                timestamp: undefined,
                val: undefined,
                val_numeric: undefined,
                status: undefined,
              }
              return;
            }
          }

          if (_internal_name === 'PRESS_CONDUCTIVITY'
              && !device.last_card_values[_internal_name]) {
            if (device.model.startsWith("press_box")) {
              device.last_card_values[_internal_name] = {
                sensor: _sensor, // deprecate
                internal_name: _internal_name,
                timestamp: undefined,
                val: undefined,
                val_numeric: undefined,
                status: undefined,
              }
              return;
            }
          }

        }

        // last_value has gone away?
        if (!device.last_values[_internal_name] &&
            device.last_card_values[_internal_name]?.val) {
          console.error("Last_value has gone away? Please report", device.last_card_values);
          delete device.last_card_values[_internal_name];
        }
      });
      device.cards_count = _.size(device.last_card_values);

      if (!SMARTBOX_MODELS.includes(device.model) && device.cards_count == 0) {
        console.log("Warning, no data/card parameters found for device", device.model, device.sn, device.name);
      }
    }

    // Normalize place info: type, unit_type, icon, etc
    public normalize_places_info(place: IPlace | IPlace[] = this.$rootScope.WGPlaces.places) {

      if (_.isArray(place)) {
        place.forEach((_place) => {
          this.normalize_places_info(_place);
        });
        return;
      }

      if (_.isNil(place)) {
        return;
      }

      _.defaults(place, {
        type: 'place',
        units: [],
        counts: {
          devices: 0,
          processes: 0,
          alarms: 0,
        },
        last_values: {},
      });
    }

    // Fix/Normalize unit info: type, unit_type, icon, etc
    public normalize_units_info(unit: IUnit | IUnit[] = this.$rootScope.WGUnits.units) {
      if (_.isArray(unit)) {
        unit.forEach((_unit) => {
          this.normalize_units_info(_unit);
        });
        return;
      }

      if (_.isNil(unit)) {
        return;
      }

      _.defaults(unit, {
        type: 'unit',
        place: undefined,
        devices: [],
        process: undefined,
        is_visible: true,
        config_fields: {},
        alarms: {},
      });

      // _.defaults(unit.config_fields, {
      //   is_user_favorite: {},
      // });

      _.defaults(unit.alarms, {
        // user_rules: [],
        user_notifications: [],
        ai_notifications: [],
        status_notifications: [],
      });

      if (!_.isEmpty(unit.protocols)) {
        unit.protocol = unit.protocols[0];
      } else {
        unit.protocol = null;
      }


      // Get unit_type if missing
      if (!unit.unit_type && unit.devices?.[0]?.unit_type) {
        let name = unit.devices[0].unit_type.toLowerCase();
        if (name.startsWith('press')) {
          unit.unit_type = 'press';
        } else if (name.startsWith('vat')) {
          unit.unit_type = 'vat';
        } else if (name.startsWith('barrel')) {
          unit.unit_type = 'barrel';
        } else if (name.startsWith('bottle')) {
          unit.unit_type = 'bottle';
        } else if (name.startsWith('cellar')) {
          unit.unit_type = 'cellar';
        }
      }
      // Check for model in first associated device
      if (!unit.unit_type && unit.devices?.[0]?.model) {
        let model = unit.devices[0].model.toLowerCase();
        if (model.startsWith('wp') || model.includes('charmat') || model.includes('volume')) {
          unit.unit_type = 'vat';
        } else if (model.includes('bung') || model.startsWith('bp')) {
          unit.unit_type = 'barrel';
        } else if (model.includes('aphro')) {
          unit.unit_type = 'bottle';
        } else if (model.includes('cellar')) {
          unit.unit_type = 'cellar';
        }
      }
      // Check in the unit name
      if (!unit.unit_type && unit.name) {
        let name = unit.name.toLowerCase();
        if (name.includes('press')) {
          unit.unit_type = 'press';
        } else if (name.includes('vat')) {
          unit.unit_type = 'vat';
        } else if (name.includes('barrel')) {
          unit.unit_type = 'barrel';
        } else if (name.includes('bottle')) {
          unit.unit_type = 'bottle';
        } else if (name.includes('cellar')) {
          unit.unit_type = 'cellar';
        }
      }

      // Define Icon from unit_type
      if (unit.unit_type === 'barrel') {
        unit.icon = 'icon icon-wg-barrel-line';
      } else if (unit.unit_type === 'vat') {
        unit.icon = 'icon icon-wg-vat-line';
      } else if (unit.unit_type === 'press') {
        unit.icon = 'icon icon-wg-press-line';
      } else if (unit.unit_type === 'bottle') {
        unit.icon = 'icon icon-wg-bottle';
      } else if (unit.unit_type === 'cellar') {
        unit.icon = 'icon icon-wg-cellar-line';
      } else {
        unit.icon = 'fa fa-question-circle noUnit';
      }
    }

    public parse_device_info(device: IDevice) {
      // if (_.isArray(device)) {
      //   device.forEach((_device) => {
      //     this.normalize_device_info(_device);
      //   });
      //   return;
      // }

      if (_.isNil(device)) {
        return;
      }

      device.configs = parseData(device.configs, {}) as IDeviceConfigs;

      // Only set keys that will be written in this function
      _.defaults(device, {
        type: 'device',
        model: "",
        sn: device.configs.sn || device.iid, // .configs.sn == legacy
        is_virtual: false,
      });

      // @ts-ignore
      device.model = device.model.toLowerCase();

      if (device.sn === device.iid && !this.AuthService.isDemoAccount()) {
        device.is_virtual = true;
      }

      if (device.model.includes('bung')) {
        device.model_name = "e-Bung";
      } else if (device.model.includes('aphrom')) {
        device.model_name = "e-Aphrom";
      } else if (device.model.startsWith('bp1')) {
        device.model_name = "Barrelplus";
      } else if (device.model.includes('wp111')) {
        device.model_name = "Wineplus Premium";
      } else if (device.model.includes('wp1')) {
        device.model_name = "Wineplus";
      } else if (device.model.includes('charmat')) {
        device.model_name = "e-Charmat";
      } else if (device.model.includes('cellar')) {
        device.model_name = "Smartcellar";
      } else {
        device.model_name = device.model.toLowerCase();
      }

      device.capabilities = _.defaults(device.capabilities, {
        force_read: !!this.can_device_force_read(device),
        config_density_read: !!this.can_device_change_density_read(device),
        control_board: !!device.model.startsWith('control'),
        fermentation_prediction: !!this.can_device_change_density_read(device),
        set_offsets: !!this.can_device_set_offsets(device),
        config_wifi: !!this.can_device_config_connection(device),
      });


      if (this.$rootScope.WG_is_mar2protect) {
        device.unit_type = "cellar";
        device.icon = 'icon-wg-charmat';
        device.tech_icon = 'icon icon-wg-cellar-line';
      }

      if (!device.icon) {
        // let _name = device.name;
        if (device.model.includes('smartbox')) {
          device.icon = 'icon-wg-smart-box';
          if (!device.tech_icon)
            device.tech_icon = 'icon-wg-smart-box';
          if (!device.unit_type)
            device.unit_type = "smartbox";
        } else if (device.model.includes('bung')) {
          device.icon = 'icon-wg-ebung-line';
          if (!device.unit_type) device.unit_type = "barrel";

        } else if (device.model.includes('wp1110')) {
          device.icon = 'icon-wg-wp-1110-line';
          if (!device.unit_type) device.unit_type = "vat";

        } else if (device.model.includes('wp1')) {
          device.icon = 'icon-wg-wp-1100-line';
          if (!device.unit_type) device.unit_type = "vat";

        } else if (device.model.includes('aphro')) {
          device.icon = 'icon-wg-aferometro_line';
          if (!device.unit_type) device.unit_type = "bottle";

        } else if (device.model.includes('smartcellar')) {
          device.icon = 'icon-wg-smartcellar-line';
          if (!device.unit_type) device.unit_type = "cellar";

        } else if (device.model.includes('bp1011')) {
          device.icon = 'icon-wg-bp-1011-line';
          if (!device.unit_type) device.unit_type = "barrel";

        } else if (device.model.includes('bp1')) {
          device.icon = 'icon-wg-bp-1010-line';
          if (!device.unit_type) device.unit_type = "barrel";

        } else if (device.model.includes('charmat')) {
          device.icon = 'icon-wg-charmat';
          if (!device.unit_type) device.unit_type = "vat";


        } else if (device.model.includes('wp2')) {
          device.icon = 'icon-wg-wp-2200-line';
          if (!device.unit_type) device.unit_type = "vat";

        } else if (device.model.includes('wp3')) {
          device.icon = 'icon-wg-wp-1100-line';
          if (!device.unit_type) device.unit_type = "vat";

        } else if (device.model.includes('ultrasonic_barrel')) {
          device.icon = 'icon-wg-ultrasonic_barrel';
          if (!device.unit_type) device.unit_type = "barrel";

        } else if (device.model.includes('ultrasonic')) {
          device.icon = 'icon-wg-ultrasonic_vat';
          if (!device.unit_type) device.unit_type = "vat";

        } else if (device.model.includes('vat_vol_height')) {
          device.icon = 'icon-wg-vat-vol_height';
          if (!device.unit_type) device.unit_type = "vat";

        } else if (device.model.includes('sv-mw')) {
          device.icon = 'icon-wg-volume';
          if (!device.unit_type) device.unit_type = "vat";

        } else if (device.model.includes('press_box')) {
          device.icon = 'icon-wg-devices';
          if (!device.unit_type) device.unit_type = "press";

        } else if (device.model.includes('ql_box')) {
          device.icon = 'icon-wg-devices';

        } else if (device.model.includes('spectral')) {
          device.icon = 'icon-wg-devices';
          if (!device.unit_type) device.unit_type = "press";

        } else if (device.model.startsWith('control')) {
          device.icon = 'icon-wg-controlboard-line';
        } else {
          device.icon = 'icon-wg-exclamation';
        }
      }

      if (!device.tech_icon) {
        let _type = device.unit_type?.toLowerCase() || '';
        if (!_type && WG_debug)
          console.warn("Missing device.unit_type!", device);
        if (_type.startsWith('press')) {
          device.tech_icon = 'icon icon-wg-press-wireless';
        } else if (_type.startsWith('vat')) {
          device.tech_icon = 'icon icon-wg-vat-rl-wireless';
        } else if (_type.startsWith('barrel')) {
          device.tech_icon = 'icon icon-wg-barrel-rl-wireless';
        } else if (_type.startsWith('bottle')) {
          device.tech_icon = 'icon icon-wg-bottle';
        } else if (_type.startsWith('cellar')) {
          device.tech_icon = 'icon icon-wg-cellar-line';
        } else {
          device.tech_icon = 'icon icon-wg-vat-line';
        }
      }
    }

    public normalize_device_info(device: IDevice) {
      // if (_.isArray(device)) {
      //   device.forEach((_device) => {
      //     this.normalize_device_info(_device);
      //   });
      //   return;
      // }

      if (_.isNil(device)) {
        return;
      }

      if (_.isNil(device.type)
          || _.isNil(device.configs)
          || _.isNil(device.model_name)
          || _.isNil(device.icon)
          || _.isNil(device.capabilities)
          || _.isNil(device.tech_icon)
          || _.isNil(device.unit_type)) {
        if (WG_debug) console.warn("Re-parsing device info! Why?", _.clone(device));
        this.parse_device_info(device);
      }

      _.defaults(device, {
        type: 'device',
        model: "",
        unit_type: "",
        internal_name: "",
        iid: "",

        configs: {},
        capabilities: {},
        streams: [],
        lkm: {},
        last_values: {},
        last_card_values: {},
        cards_count: 0,

        unit: {},
        sample_interval: 0,
        last_read: 0,
        last_comm: 0,
        status: '',
        comm_status: {
          last_gw: null,
          last_mode: null,
          last_wifi_timestamp: 0,
          last_lora_timestamp: 0,
        },
        version: null,
        hw_version: null,
      });

    }

    public normalize_sensor_info(sensor: ISensor | ISensor[]) {
      if (_.isArray(sensor)) {
        sensor.forEach((_sensor) => {
          this.normalize_sensor_info(_sensor);
        });
        return;
      }

      if (_.isNil(sensor)) {
        return;
      }
      sensor.configs = parseData(sensor.configs, {}, sensor.internal_name) as ISensorConfigs;

      _.defaults(sensor, {
        internal_name: sensor.stream,
        name_sref: undefined,
        configs: {},
        admin: true,
        unit: undefined,
        unit_sref: undefined,
        unit_orig: undefined,
        unit_orig_sref: undefined,
        conversion: undefined,
      })

      _.defaults(sensor.configs, {
        manual: false,
        icon: undefined,
        sub_query: null // In case the payload.value is a number there should not be any subquery
      })

      // Uniformize data not dependent on MasterSensor (might not yet be processed)
      if (sensor.name) {
        sensor.name = this.translate(parseData(sensor.name)); // If it's a json (an object), get it's translation
      }

      if (!sensor.unit || sensor.unit === "NA" || sensor.unit === "N/A") {
        sensor.unit = null;
      }
    }

    public syncUnitDeviceName(unit: IUnit, device: IDevice) {
      let unitNameDefined = !_.isEmpty(unit.name) && unit.name !== device.name && unit.name !== device.sn;

      if (_.isEmpty(device.name)) {
        device.name = device.name || device.sn || device.internal_name || device.model;
      }

      if (!unitNameDefined && unit.name != device.name) {
        unit.name = device.name;
      }

      // let deviceNameDefined = !_.isEmpty(device.name) && device.name !== device.sn && device.name !== ("_" + device.sn) && device.name !== device.internal_name;
      // if (!deviceNameDefined && unitNameDefined && unit.name !== device.name) {
      //   device.name = device.name + ' - ' + unit.name;
      // }
    }


    private global_apply_timers: angular.IPromise<any>[] = [];
    private global_apply_received_topics = {};

    /**
     * Batches multiple calls on the same device to a single one, performing a single $scope.$apply()
     * in AngularJS, this.$timeout = this.$rootScope.$Apply(setTimeout(...)), $applying when it is executed
     * @param _topic: string - Only the first schedule for each topic is scheduled.
     * @param _timeout - ms to wait for
     */
    public global_apply_schedule(_topic = '', _timeout = 1000) {
      if (_topic) {
        if (this.global_apply_received_topics[_topic]) {
          // if (WG_debug) console.warn('global_$apply got Repeated topic!!');
          return;
        }
        this.global_apply_received_topics[_topic] = true;
      }
      // if (this.global_apply_timers) {
      //   this.$timeout.cancel(this.global_apply_timers);
      //   // if (WG_debug) console.log('global_$apply canceled, waiting ' + _timeout);
      // }
      this.global_apply_timers.push(this.$timeout(() => {
        this.global_apply_cancel();
        // if (WG_debug) console.log('global_$apply running');
      }, _timeout));
    }

    public global_apply_cancel() {
      if (!this.global_apply_timers) return;

      _.forEach(this.global_apply_received_topics,)
      for (let t of this.global_apply_timers) {
        this.$timeout.cancel(t);
      }
      this.global_apply_timers = emptyOrCreateArray(this.global_apply_timers);
      this.global_apply_received_topics = emptyOrCreateDict(this.global_apply_received_topics);
    }

    public get_smartbox(internal_name?: string, iid?: string): IDevice {
      // if(iid=="0242ac19000b") {
      // if(WG_debug) console.warn("Platform GW detected! warning!", iid, internal_name);
      // return null;
      // }
      let _smartbox: IDevice = null;
      if (iid)
        _smartbox = _.find(this.$rootScope.WGDevices.devices, {iid: iid});
      if (internal_name) {
        if (!_smartbox)
          _smartbox = _.find(this.$rootScope.WGDevices.devices, {internal_name: internal_name});
        if (!_smartbox)
          _smartbox = _.find(this.$rootScope.WGDevices.devices, {iid: internal_name});
        if (!_smartbox)
          _smartbox = _.find(this.$rootScope.WGDevices.devices, {sn: internal_name});
      }
      // if (WG_debug && !_smartbox) {
      //   console.warn("Smartbox not found:", internal_name, iid);
      // }
      return _smartbox;
    }

    public update_comm_status(device: IDevice, type: string, timestamp: number, mode: string, ssid: string, gw: string, rssi?: number, snr?: number, quality?: number, gw_iid?: string, gws_received: {
      gw: string,
      rssi: number,
      snr: number
    }[] = null, can_set_mode = true): boolean {
      // can_set_mode = true;// buggy, for now
      if (rssi > 0) { // Fix when rssi is positive (legacy GW bug, int8 used instead of uint8)
        // if (WG_debug) console.warn('Got positive RSSI. Negating: ', rssi);
        rssi = rssi < 256 ? (rssi - 256) : -rssi;
      }
      let gw_lower = gw?.toLowerCase() || '';
      let mode_lower = mode?.toLowerCase() || '';
      if (!type) {
        if (gw_lower.includes("st0-wg") ||
            gw_lower.includes("http") ||
            gw_lower.includes("wifi") || gw_lower.includes("wi-fi") ||
            mode_lower.includes("wifi") || mode_lower.includes("wi-fi")
        ) {
          type = "wifi";
        }
        if (gw_lower.includes("chirpstack")
            || gw_lower.includes("lora")
            || mode_lower.includes("lora")) {
          type = "lora";
        }

        if (!type) {
          // if (WG_debug) console.warn("Unknown type for comm_status", type, mode, gw);
          return false;
        }
      }

      if (type == "wifi") {
        // WIFI_INFOS, SLEEP.infos, SLEEP_INFOS, WIFI_SSID, SSID, WIFI_RSSI, RSSI
        if (timestamp < device.comm_status.last_wifi_timestamp - 2000) { // Outdated info received
          return false;
        }
        if (timestamp > device.comm_status.last_wifi_timestamp + 5000) { // Clear previous outdated info
          device.comm_status.last_wifi_mode = null;
          device.comm_status.last_wifi_ssid = null;
          device.comm_status.last_wifi_gw = null;
          device.comm_status.last_wifi_gw_id = null;
          device.comm_status.last_wifi_rssi = null;
          device.comm_status.last_wifi_snr = null;
          device.comm_status.last_wifi_quality = null;
          device.comm_status.last_wifi_class = null;
        }
        let _smartbox = this.get_smartbox(gw, gw_iid);
        let _mode: ICommStatus['last_wifi_mode'] = null;
        if (gw || mode) {
          if (mode_lower.includes("smartbox") ||
              gw_lower.includes("wg-gw")) {
            _mode = "Smartbox Wi-Fi";
          } else if (gw_lower.includes("st0-wg") ||
              gw_lower.includes("wg-vm0") ||
              gw_lower.includes("gateway-http")) {
            _mode = "Local Wi-Fi";
            gw = null;
          } else {
            _mode = "Wi-Fi";
            gw = "Wi-Fi";
          }
        }
        // merge
        if (can_set_mode || !device.comm_status.last_wifi_timestamp)
          device.comm_status.last_wifi_timestamp = timestamp;
        device.comm_status.last_wifi_mode = device.comm_status.last_wifi_mode || _mode;
        device.comm_status.last_wifi_ssid = device.comm_status.last_wifi_ssid || ssid;
        device.comm_status.last_wifi_gw = device.comm_status.last_wifi_gw || _smartbox?.name || gw || _smartbox?.sn;
        device.comm_status.last_wifi_gw_id = device.comm_status.last_wifi_gw_id || _smartbox?.id;

        if (!device.comm_status.last_wifi_rssi && !!rssi) {
          device.comm_status.last_wifi_quality = convert(rssi, 'wifi_quality', null, false);

          // if (_smartbox?.model != "smartbox" && !_.find(this.$rootScope.WGDevices.devices, {model: "smartbox"})) {
          if (!device.comm_status.last_lora_timestamp) {
            // If LoRa was never used, it's probably not available.
            // Show red when WiFi signal is low, since there's no fallback
            if (device.comm_status.last_wifi_quality < 20) {
              device.comm_status.last_wifi_class = 'DANGER';
            } else if (device.comm_status.last_wifi_quality < 40) {
              device.comm_status.last_wifi_class = 'WARNING';
            } else {
              device.comm_status.last_wifi_class = null;
            }
          } else {
            device.comm_status.last_wifi_class = null;
          }
        }
        device.comm_status.last_wifi_rssi = device.comm_status.last_wifi_rssi || rssi;

      } else if (type == "lora") {
        // LORA_INFOS, SLEEP.infos, SLEEP_INFOS, LORA_RSSI
        if (timestamp < device.comm_status.last_lora_timestamp) {
          return false;
        }
        if (timestamp > device.comm_status.last_lora_timestamp + 5000) { // Clear previous info if outdated
          device.comm_status.last_lora_mode = null;
          device.comm_status.last_lora_gw = null;
          device.comm_status.last_lora_gw_id = null;
          device.comm_status.last_lora_rssi = null;
          device.comm_status.last_lora_snr = null;
          device.comm_status.last_lora_quality = null;
          device.comm_status.last_lora_class = null;
        }
        let _mode: ICommStatus['last_lora_mode'] = null;
        if (gw || mode) {
          if (gw_lower.includes("chirpstack")
              || gw_lower.includes("vm0")
              || (gw_lower.includes("lora") && gw_lower.includes("wan"))) {
            _mode = "LoRa_WAN";
            gw = null; // Don't show "cloud" gateways
          } else if (mode_lower.includes("lora")
              && mode_lower.includes("wan")) {
            _mode = "LoRa_WAN";
          } else {
            _mode = "LoRa_P2P";
          }
        }
        if (_mode == "LoRa_WAN" && gws_received && !_.isNil(rssi)) {
          // Get used gateway from the list of all_gws and rssi. Or choose the one with highest rssi
          let _best_rssi = -9999;
          for (let _gw of gws_received) {
            if (_gw.rssi == rssi) {
              gw_iid = _gw.gw;
              gw = null;
              break;
            }
            if (_gw.rssi + (_gw.snr || 0) > _best_rssi) {
              _best_rssi = _gw.rssi + (_gw.snr || 0);
              gw_iid = _gw.gw;
              gw = null;
            }
          }
        }

        let _smartbox = this.get_smartbox(gw, gw_iid);
        // merge
        if (can_set_mode || !device.comm_status.last_lora_timestamp)
          device.comm_status.last_lora_timestamp = timestamp;
        device.comm_status.last_lora_mode = device.comm_status.last_lora_mode || _mode;
        device.comm_status.last_lora_gw = device.comm_status.last_lora_gw || _smartbox?.name || gw || _smartbox?.sn;
        device.comm_status.last_lora_gw_id = device.comm_status.last_lora_gw_id || _smartbox?.id;

        if (!device.comm_status.last_lora_rssi && rssi) {
          device.comm_status.last_lora_quality = convert(rssi + (snr || 0), 'lora_quality', null, false);
          if (device.comm_status.last_lora_quality < 20) {
            device.comm_status.last_lora_class = 'DANGER';
          } else if (device.comm_status.last_lora_quality < 40) {
            device.comm_status.last_lora_class = 'WARNING';
          } else {
            device.comm_status.last_lora_class = null;
          }
          // LoRa Available. Don't show WiFi alerts
          device.comm_status.last_wifi_class = null;
        }
        device.comm_status.last_lora_rssi = device.comm_status.last_lora_rssi || rssi;
        if (!_.isFinite(device.comm_status.last_lora_snr)) {
          if (_.isFinite(snr))
            device.comm_status.last_lora_snr = snr;
        }
      } else {
        console.error("unknown type:", type);
        return false;
      }
      return true;
    }

    public parse_comm_status(device: IDevice, lkm: ILastKnownMessages) {
      let updated = false;

      let _payload;

      if (lkm['MODE']?.payload?.value) { // What comm Mode is preferred and acknowledged from the device.
        device.comm_status.preferred_mode = lkm['MODE'].payload.value;
      } else if (lkm.configs?.payload?.value?.mode) { // What comm Mode is configured as preferred
        device.comm_status.preferred_mode = lkm.configs?.payload?.value?.mode;
      }

      // if (lkm['MODE']?.payload?.value) { // What comm Mode is preferred and acknowledged from the device.
      //   _payload = lkm['MODE'].payload;
      //   let _simple_mode = _payload.value.toLowerCase();
      //   if (_simple_mode.includes("wifi") || _simple_mode.includes("wi-fi")) {
      //     _simple_mode = "wifi";
      //   } else if (_simple_mode.includes("lora")) {
      //     _simple_mode = "lora";
      //   }
      //   updated = this.update_comm_status(device, _simple_mode
      //       , _payload.timestamp
      //       , _payload.value || null
      //       , null
      //       , _payload['gw'] || null
      //       , null
      //       , null
      //       , null
      //       , _payload['gw_iid'] || null) || updated;
      // }

      if (lkm['SLEEP']?.payload?.['gw'] || lkm['SLEEP']?.payload?.['infos']?.via) { // Info from the GW
        _payload = lkm['SLEEP'].payload;
        updated = this.update_comm_status(device, null
            , _payload.timestamp
            , _payload['infos']?.via || null
            , _payload['infos']?.['ssid'] || null
            , _payload['infos']?.['gw'] || _payload['gw'] || _payload['infos']?.['wg'] || null
            , _payload['infos']?.['rssi'] || null
            , _payload['infos']?.['snr'] || null
            , _payload['infos']?.['quality'] || null
            , _payload['infos']?.['gw_iid'] || _payload['gw_iid'] || null) || updated;
      }

      if (lkm['SLEEP_INFOS']?.payload?.value?.via) { // Legacy, info from the GW, replaced by SLEEP.payload.infos
        _payload = lkm['SLEEP_INFOS'].payload;
        updated = this.update_comm_status(device, null
            , _payload.timestamp
            , _payload.value.via || null
            , _payload.value['ssid'] || _payload['ssid'] || null
            , _payload.value['gw'] || _payload['gw'] || null
            , _payload.value['rssi'] || _payload['rssi'] || null
            , _payload.value['snr'] || _payload['snr'] || null
            , _payload.value['quality'] || _payload['quality'] || null
            , _payload.value['gw_iid'] || _payload['gw_iid'] || null) || updated;
      }

      if (lkm['WIFI_INFOS']?.payload?.value?.signal?.value_1) { // Provides Wi-Fi RSSI from the GW (packet received from sensor)
        _payload = lkm['WIFI_INFOS'].payload;
        updated = this.update_comm_status(device, "wifi"
            , _payload.timestamp
            , _payload.value.via || null
            , _payload.value['ssid'] || _payload['ssid'] || null
            , _payload.value['gw'] || _payload['gw'] || null
            , _payload.value['signal']['value_1'] || null
            , null
            , _payload.value['quality'] || _payload['quality'] || null
            , _payload.value['gw_iid'] || _payload['gw_iid'] || null) || updated;
      }

      if (lkm['LORA_INFOS']?.payload?.value) { // Provides Lora RSSI from the GW (packet received from sensor)
        _payload = lkm['LORA_INFOS'].payload;
        if (_payload.value['rssi'] || _payload['rssi'] || _payload.value['signal']?.['value_1']) {
          updated = this.update_comm_status(device, "lora"
              , _payload.timestamp
              , _payload.value.via || null
              , null
              , _payload['gw'] || _payload.value['gw'] || null
              , _payload.value['rssi'] || _payload['rssi'] || _payload.value['signal']?.['value_1'] || null
              , _payload.value['snr'] || _payload['snr'] || null
              , _payload.value['quality'] || _payload['quality'] || null
              , _payload.value['gw_iid'] || _payload['gw_iid'] || null
              , _payload.value['gws'] || null) || updated;
        }
      }

      // TODO: From now on, these doesn't indicate which mode was used

      if (lkm['WIFI_SSID']?.payload?.value) { // Provides Wi-Fi SSID as seen from the device
        _payload = lkm['WIFI_SSID'].payload;
        updated = this.update_comm_status(device, "wifi"
            , _payload.timestamp
            , _payload.via || _payload.value.via || null
            , _payload.value || _payload['ssid'] || _payload.value['ssid'] || null
            , _payload.value['gw'] || _payload['gw'] || null
            , _payload.value['rssi'] || _payload['rssi'] || null
            , _payload.value['snr'] || _payload['snr'] || null
            , _payload.value['quality'] || _payload['quality'] || null
            , _payload.value['gw_iid'] || _payload['gw_iid'] || null
            , null
            , false) || updated;
      }

      if (lkm['SSID']?.payload?.value) { // Legacy. Provides Wi-Fi SSID as seen from the GW
        _payload = lkm['SSID'].payload;
        updated = this.update_comm_status(device, "wifi"
            , _payload.timestamp
            , _payload.via || _payload.value.via || null
            , _payload.value || null
            , _payload['gw'] || _payload.value['gw'] || null
            , _payload['rssi'] || _payload.value['rssi'] || null
            , _payload['snr'] || _payload.value['snr'] || null
            , _payload['quality'] || _payload.value['quality'] || null
            , _payload['gw_iid'] || _payload.value['gw_iid'] || null
            , null
            , false) || updated;
      }

      if (lkm['WIFI_RSSI']?.payload?.value) { // Provides Wi-Fi RSSI as seen from the sensor - Packet arriving from GW to Sensor
        _payload = lkm['WIFI_RSSI'].payload;
        updated = this.update_comm_status(device, "wifi"
            , _payload.timestamp
            , _payload.via || null
            , _payload['ssid'] || null
            , _payload['gw'] || null
            , _payload.value || null
            , lkm['WIFI_SNR']?.payload?.value || null
            , _payload['quality'] || null
            , _payload['gw_iid'] || null
            , null
            , false) || updated;
      }

      if (lkm['RSSI']?.payload?.value &&
          !lkm['RSSI'].payload['gw']?.includes("lora")) { // Legacy. Provides Wi-Fi RSSI as seen from the GW. Expensive, GWs don't estimate this.
        _payload = lkm['RSSI'].payload;
        updated = this.update_comm_status(device, "wifi"
            , _payload.timestamp
            , _payload.via || null // not present
            , _payload['ssid'] || null // not present
            , _payload['gw'] || null
            , _payload.value || null
            , lkm['SNR']?.payload?.value || null
            , _payload['quality'] || null // not present
            , _payload['gw_iid'] || null
            , null
            , false) || updated; // not present
      }


      if (lkm['LORA_RSSI']?.payload?.value) { // Provides Lora RSSI as seen from the sensor (received from GW)
        _payload = lkm['LORA_RSSI'].payload;
        updated = this.update_comm_status(device, "lora"
            , _payload.timestamp
            , _payload.via || null
            , null
            , _payload['gw'] || _payload.value['gw'] || null
            , _payload.value || null
            , lkm['LORA_SNR']?.payload?.value || null
            , _payload['quality'] || null
            , _payload['gw_iid'] || _payload.value['gw_iid'] || null
            , null
            , false) || updated;
      }


      if (updated || !device.via_mode) {

        // Uniformize strings
        if (device.comm_status.last_wifi_mode?.includes("Local")) {
          device.comm_status.last_wifi_description = this.$translate.instant("app.devices.units.LOCAL_WIFI");
          if (device.comm_status.last_wifi_ssid) {
            device.comm_status.last_wifi_description += ": " + device.comm_status.last_wifi_ssid;
          }
          // } else if (device.comm_status.last_wifi_mode?.toLowerCase().includes("smartbox")) {
          //   device.comm_status.last_wifi_description = "Smartbox Wi-Fi";
          //   if (device.comm_status.last_wifi_gw)
          //     device.comm_status.last_wifi_description += " - " + device.comm_status.last_wifi_gw;
        } else {
          device.comm_status.last_wifi_description = device.comm_status.last_wifi_mode;
          if (device.comm_status.last_wifi_gw)
            device.comm_status.last_wifi_description += " - " + device.comm_status.last_wifi_gw;
        }

        device.comm_status.last_lora_description = device.comm_status.last_lora_mode;
        if (device.comm_status.last_lora_gw) {
          device.comm_status.last_lora_description += " - " + device.comm_status.last_lora_gw;
        }

        // Save most recent in the legacy via/via_mode
        if (device.comm_status.last_wifi_timestamp > device.comm_status.last_lora_timestamp) {
          device.comm_status.last_gw_id = device.comm_status.last_wifi_gw_id || null;
          device.comm_status.last_gw = device.comm_status.last_wifi_gw || null;
          device.comm_status.last_mode = device.comm_status.last_wifi_mode || "Wi-Fi";
          device.comm_status.last_description = device.comm_status.last_wifi_description || "Wi-Fi";
        } else if (device.comm_status.last_lora_timestamp > 0) {
          device.comm_status.last_gw_id = device.comm_status.last_lora_gw_id || null;
          device.comm_status.last_gw = device.comm_status.last_lora_gw || null;
          device.comm_status.last_mode = device.comm_status.last_lora_mode || "LoRa";
          device.comm_status.last_description = device.comm_status.last_lora_description || "LoRa";
        }

        // Legacy support
        device.via = device.comm_status.last_gw || null;
        device.via_mode = device.comm_status.last_mode || null;
      }

      return updated;
    }

    /**
     * Singleton-like function. Batches multiple calls on the same device to a single one,
     * reducing number of changes to html-binded values
     * @param device device to analyze
     * @param _timeout - ms to wait for
     * @param replace - If any existing timeout should be replaced
     */
    public update_device_status_soon(device: IDevice, _timeout = null, replace = true, refresh_device = false) {
      if (device.status_update_timer && !replace) {
        // Device already scheduled with unspecified timeout. Ignore.
        return;
      }
      if (!_timeout) {
        _timeout = 500;
      }
      if (device.status_update_timer) {
        this.$timeout.cancel(device.status_update_timer);
      }
      // if (WG_debug) console.log('DataUtils. update_device_status scheduled for id', device.id, _timeout);
      device.status_update_timer = this.$timeout(() => {
        // device.status_update_timer = undefined;
        // if (WG_debug) console.time("update_device_status id" + device.id);


        // this.$rootScope.WGDevices.update_singular(device.id);

        this.update_device_status(device);
        this.update_device_card_parameters(device);

        this.global_apply_schedule("device-status-updated" + device.id);

        // if (WG_debug) console.timeEnd("update_device_status id" + device.id);
      }, _timeout, false);
    }

    /**
     * Re-estimates "status" from a device's data.
     * last_read, on/off, status_density_reading, etc
     * Also, reschedules to min(device_expires, device.last_comm + ALLOWED_PROCESSING_DELAY, 1h);
     * @param device to analyze
     */
    public update_device_status(device: IDevice) {
      // if (WG_debug) console.log("DataUtils update_device_status", device);
      if (device.status_update_timer) {
        this.$timeout.cancel(device.status_update_timer);
        device.status_update_timer = undefined;
      }

      if (!device.capabilities || !device.last_card_values) {
        console.error("Device was not yet processed. Please report!", device.id, device.name, device.sn);
        this.normalize_device_info(device);
      }

      if ((device.model == 'virtual_smartbox')) {
        device.status = 'DEMO';
        return;
      }

      // Current browser time (ms since Epoch in UTC)
      let _current_time = new Date().getTime();

      // if (WG_debug) console.info(Highcharts.dateFormat('%m/%d %H:%M:%S', _current_time) + " Updating Device status!", device.model, device.sn, device.name, device);

      // "Fix" desynchronized clocks in the future
      if (device.last_read > _current_time + 1 * 60 * 1000) {
        if (device.last_read > _current_time + 5 * 60 * 1000) {
          // If clocks differ by more than 5 min, mark as N/A
          console.error("Significant clock error detected: " + Math.round((device.last_read - _current_time) / 1000) + "s in the future! For device: ", device.sn);
        }
        device.last_read = _current_time;
      }

      if (device.last_comm > _current_time + 1 * 60 * 1000) {
        if (device.last_comm > _current_time + 5 * 60 * 1000 && device.last_comm < _current_time + 7 * 24 * 60 * 60 * 1000) {
          // If clocks differ by more than 5 min and less then 1 week, warn
          console.error("Significant clock error detected on last_comm: " + Math.round((device.last_comm - _current_time) / 1000) + "s in the future! For device: ", device.sn);
        }
        device.last_comm = _current_time;
      }

      let _last_sleep_timestamp = 0;
      // in ms
      let _last_sleep_value = 0;
      // in ms
      let _real_interval = 0;
      // in ms
      let _last_sleep_aquisition_time = 0;

      let _lkm = device.lkm;
      if (!_.isEmpty(_lkm)) {

        if (_lkm['SLEEP']?.payload?.timestamp >= _last_sleep_timestamp) {
          _last_sleep_timestamp = _lkm['SLEEP'].payload.timestamp;
          _last_sleep_value =
              _lkm['SLEEP'].payload.infos?.sleep * 1000 ||
              _lkm['SLEEP'].payload.value * 1000 || _last_sleep_value;
          _real_interval = _lkm['SLEEP'].payload.infos?.real_interval * 1000;
          _last_sleep_aquisition_time = _lkm['SLEEP'].payload.infos?.acquisition_time * 1000 || 0;
        }

        if (_lkm['SLEEP_INFOS']?.payload?.timestamp >= _last_sleep_timestamp) {
          _last_sleep_timestamp = _lkm['SLEEP_INFOS'].payload.timestamp;
          _real_interval = _lkm['SLEEP_INFOS'].payload.value?.real_interval * 1000 || _real_interval;
          _last_sleep_value = _lkm['SLEEP_INFOS'].payload.value?.sleep * 1000 || _last_sleep_value;
        }
        if (!_last_sleep_value || _last_sleep_value < 1001) {
          if (!_real_interval) {
            if (WG_debug) console.warn("No SLEEP nor SLEEP_INFOS", device);
          } else {
            if (WG_debug) console.warn("Very small Sleep. Using interval", _last_sleep_value, _real_interval);
            _last_sleep_value = _real_interval;
          }
        }
        if (_last_sleep_aquisition_time == 0
            && _lkm['ACQUISITION_TIME']?.payload?.timestamp >= _last_sleep_timestamp) {
          _last_sleep_aquisition_time = _lkm['ACQUISITION_TIME']?.payload?.value * 1000 || 0;
        }

        if (_real_interval > 1000) {
          device.sample_interval = _real_interval;
        } else {
          // Real_interval not available. Estimate it from Sleep
          device.sample_interval = device.sample_interval || Math.round(_last_sleep_value / (30 * 60 * 1000)) * 30 * 60 * 1000;

          if (device.sample_interval < 1001) {
            // Smartbox'es have a fixed sample_interval
            if (['smartbox', 'press_box'].includes(device.model)) {
              device.sample_interval = 1 * 60 * 60 * 1000;
            } else {
              device.sample_interval = 4 * 60 * 60 * 1000;
            }
          } else {
            // SLEEP comes with some scheduling shenanigans. Round it here
            if (device.sample_interval > 46 * 60 * 1000) {
              device.sample_interval = Math.round(device.sample_interval / (60 * 60 * 1000)) * 60 * 60 * 1000;
            } else if (device.sample_interval > 23 * 60 * 1000) {
              device.sample_interval = Math.round(device.sample_interval / (30 * 60 * 1000)) * 30 * 60 * 1000;
            } else {
              device.sample_interval = Math.round(device.sample_interval / (60 * 1000)) * 60 * 1000;
            }
            if (device.sample_interval < 1 * 60 * 1000) {
              device.sample_interval = 1 * 60 * 1000;
            }
          }

          if (_last_sleep_value < 1001) {
            _last_sleep_value = device.sample_interval;
          }
          // if (WG_debug) {
          //   if (!['smartbox', 'press_box'].includes(device.model))
          //     console.warn("_real_interval not available. Got from Sleep:", _real_interval, device.sample_interval, device);
          // }
        }


        // comm_status
        this.parse_comm_status(device, _lkm);

        if (_lkm['VPROG']?.payload?.value) {
          // Extract the smartboxs version: "v1.0.5-15" from "master@07ff4ac-v1.0.5-15-g07ff4ac-dirty" using regex
          let _match = _lkm['VPROG']?.payload?.['VERSION']?.match(/.*-(v[\d\.]+(\-\d+)?)-.*/);
          if (_match?.[1]) {
            device.version = _match[1];
          } else {
            device.version = _lkm['VPROG']?.payload?.value;
          }

          if (device.model?.startsWith("wp") || device.model?.startsWith("bp")) {
            if (!vprog_gte(device.version, '8.3')) {
              device.version_status = 'OLD';
            } else {
              device.version_status = 'UPDATED';
            }
          } else if (['smartbung', 'aphrometer', 'smartcellar'].includes(device.model)) {
            if (!vprog_gte(device.version, '1.2.30')) {
              device.version_status = 'OLD';
            } else {
              device.version_status = 'UPDATED';
            }
          }
        }
        if (_lkm['WG_FW_ID']?.payload?.value) {
          device.hw_version = _lkm['WG_FW_ID'].payload.value;
        }

        if (_lkm['configs']?.payload?.value) {
          // if (WG_debug) console.debug("Evaluating Configs", _lkm['configs']?.payload?.value);

          if (_.isNil(_lkm['configs'].payload.value.ota) || _lkm['configs'].payload.value.ota == 0) {
            device.status_ota = null;
          } else if (_lkm['configs'].payload.value.ota == 1) {
            //if (WG_debug) console.warn("OTA is PENDING", device);
            if (device.status_ota != 'ONGOING') {
              device.status_ota = 'PENDING';
            }
          }
        }

      }


      // Update status of every LSV/Parameter. Default == 'OK'
      _.forEach(device.last_values, (_lsv: ISensorReading) => {
        // let _age = Math.abs(_current_time - _lsv.timestamp)
        // _lsv.age = moment.duration(-_age).humanize(true);


        if (COMMANDS_STREAMS.includes(_lsv.sensor.internal_name.toUpperCase())
            // || AUXILIARY_STREAMS.includes(_lsv.sensor.internal_name)
            || IGNORED_STREAMS.includes(_lsv.sensor.internal_name.toUpperCase())
        ) {
          _lsv.status = 'OK';
          _lsv.status_sref = '';
          return;
        }

        let _missed_expiration = ALLOWED_PROCESSING_DELAY;
        if (SIMULATOR_STREAMS.includes(_lsv.sensor.stream) || SIMULATOR_STREAMS_MANUAL.includes(_lsv.sensor.stream)) {
          // FERMENT_SIMULATOR is estimated every 3 hours only
          _missed_expiration = 3 * 60 * 60 * 1000 + ALLOWED_PROCESSING_DELAY;
        }
        if (_lsv.sensor.configs.manual) {
          _missed_expiration = MANUAL_MEASUREMENTS_ALLOWED_DELAY;
          // if (WG_debug) console.log("update_device_status, Manual Entry");
        }

        let _new_lsv_status: ISensorReading['status'] = null;
        let _new_lsv_status_sref: ISensorReading['status_sref'] = "";

        // Manual Entry marked as deleted. Hide value
        if (!_new_lsv_status
            && _lsv.sensor.configs.manual
            && _lsv['deleted'] === true) {
          _lsv.val = '-';
          _lsv.val_numeric = null;
        }

        // Every sensor is more than 1 months old. Show last value with a red !
        if (!_new_lsv_status
            && !this.AuthService.isDemoAccount()
            && !_lsv.sensor.configs.manual
            && device.last_read < _current_time - SENSOR_READ_AGE_TIMEOUT) {
          _new_lsv_status = 'OLD';
          _new_lsv_status_sref = 'app.measurement.status.OLD';
        }

        // Updated on last read with slack. Nothing else to analyze
        if (!_new_lsv_status
            && _lsv.timestamp >= device.last_comm - _missed_expiration) {
          _new_lsv_status = 'OK';
          _new_lsv_status_sref = '';
        }

        // Allowed processing delay has passed, without this parameter providing useful data.
        if (!_new_lsv_status
            && _lsv.timestamp < device.last_read
            && _current_time >= _lsv.timestamp + _missed_expiration) {

          // if (!_new_lsv_status &&
          //     SIMULATOR_STREAMS.includes(_lsv.sensor.stream)) {
          //   _new_lsv_status = 'OK';
          //   _new_lsv_status_sref = '';
          // }
          // if (!_new_lsv_status &&
          //     SIMULATOR_STREAMS_MANUAL.includes(_lsv.sensor.stream)) {
          //   _new_lsv_status = 'OK';
          //   _new_lsv_status_sref = '';
          // }

          if (!_new_lsv_status
              && ['PRESS_VOLUME_TOTALIZER_A', 'PRESS_VOLUME_TOTALIZER'].includes(_lsv.sensor.stream)) {
            _new_lsv_status = 'OK';
            _new_lsv_status_sref = '';
          }

          if (!_new_lsv_status
              && ['WAKEUP_REASON'].includes(_lsv.sensor.internal_name)) {
            _lsv.val = '-'; // not available last measurement, but that's normal
            _lsv.val_numeric = null;
            _new_lsv_status = 'OK';
            _new_lsv_status_sref = '';
          }

          // Current LSV is missing and there's a newer LAT_A/DENS_A showing we are running Dry
          if (!_new_lsv_status &&
              (_lsv.sensor.internal_name.includes("LLVA_LAT") || _lsv.sensor.internal_name.includes("LDENSA") || _lsv.sensor.internal_name.includes("VOLUME_TREAT")) &&
              ((device.last_values['LLVA_LAT']?.timestamp > _lsv.timestamp && parseInt(<string>device.last_values['LLVA_LAT']?.val_orig) < 300) ||
                  (device.last_values['LDENSAF']?.timestamp > _lsv.timestamp && parseInt(<string>device.last_values['LDENSAF']?.val_orig) < 10000))) {

            // if (WG_debug && _lsv.status !== 'DENS_NO_LIQUID') console.log("We are running Dry", _lsv.sensor.internal_name, _lsv);
            _lsv.val = '-';
            _lsv.val_numeric = null;
            _new_lsv_status = 'DENS_NO_LIQUID';
            _new_lsv_status_sref = 'app.overview.device.status.DENS_NO_LIQUID';
          }

          // When WPs are turned on, only Temp is available and with an acquisition_time < 15s.
          if (!_new_lsv_status
              && device.model?.startsWith("wp")
              && (_lsv.sensor.internal_name.includes("LLVA_LAT") || _lsv.sensor.internal_name.includes("LDENSA"))
              && (_last_sleep_aquisition_time > 0 && _last_sleep_aquisition_time < 15000)) {

            _lsv.val = '-';
            _lsv.val_numeric = null;
            _new_lsv_status = 'WP_BOOTING';
            _new_lsv_status_sref = 'app.overview.device.status.WP_BOOTING';
          }

          if (!_new_lsv_status &&
              (_lsv.sensor.internal_name.includes("LLVA_LAT") || _lsv.sensor.internal_name.includes("LDENSA")) &&
              device.last_values['LDENSA_STD']?.timestamp > _lsv.timestamp &&
              parseInt(<string>device.last_values['LDENSA_STD']?.val_orig) > 120) {
            // DENS_HIGH_STD - DENS e AI_ e LEVEL podem não ter valores


            _lsv.val = '-';
            _lsv.val_numeric = null;
            _new_lsv_status = 'DENS_HIGH_STD';
            _new_lsv_status_sref = 'app.overview.device.status.DENS_HIGH_STD';
          }

          // No explanation. Still delayed...
          if (!_new_lsv_status) {
            // if (!_new_lsv_status &&
            //     device.last_read > _lsv.timestamp + 2.5 * (_sample_interval || 1 * 60 * 60 * 1000)) {
            // Missed 3 consecutive readings of this LSV
            // if (WG_debug) console.log("Missed 3 consecutive readings of this parameter", _lsv);
            if (!_lsv.sensor.configs.manual) {
              _lsv.val = '-';
              _lsv.val_numeric = null;
            }
            _new_lsv_status = 'FAIL';
            _new_lsv_status_sref = 'app.overview.device.status.FAIL';

            // } else if (device.last_read > _lsv.timestamp + _missed_expiration) {
            //   // Later than other sensors
            //   if (WG_debug && _new_lsv_status !== 'FAIL') console.log("Current param is missing", _lsv.sensor.internal_name, _lsv);
            //   _lsv.val = '-';
            //   _lsv.val_numeric = null;
            //   _new_lsv_status = 'MISSED';
            //   _new_lsv_status_sref = 'app.overview.device.status.FAIL';
          }
        }


        if (_new_lsv_status && _new_lsv_status != _lsv.status) {
          _lsv.status = _new_lsv_status;
          _lsv.status_sref = _new_lsv_status_sref;
        }
      })

      // on smartbox-time
      let _next_comm = device.next_read || -1;
      // Estimate next_read timestamp. Some SLEEP may be lost or late.
      if (_last_sleep_value > 0 && _last_sleep_timestamp > 0 && _last_sleep_timestamp >= device.last_read - 10 * 1000) {
        // Sleep is on-time. Use it
        _next_comm = _last_sleep_timestamp + _last_sleep_value;
        // if (WG_debug) console.debug("Using last_sleep to estimate next_read", _last_sleep_timestamp + '+' + _last_sleep_value, device.last_comm + '+' + device.sample_interval);
      } else if (device.last_comm > 0) {
        _next_comm = device.last_comm + device.sample_interval;
        // if (WG_debug) console.debug("Sleep is delayed. Using last_comm to estimate next_read",
        //     {
        //       sn: device.sn,
        //       sleep: Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', _last_sleep_timestamp) + ' + ' + Highcharts.dateFormat('%H:%M:%S', _last_sleep_value),
        //       last_comm: Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', device.last_comm) + ' + ' + Highcharts.dateFormat('%H:%M:%S', device.sample_interval)
        //     });
      } else {
        if (WG_debug) console.debug("New device. No data yet.", device);
      }
      device.next_read = _next_comm;

      // On browser-time
      let next_comm_expiration = _next_comm + STATE_ON_TIME_WINDOW + this.ALLOWED_TIME_DRIFT;
      if (device.capabilities.control_board) { // Allow double the delay on control boards
        next_comm_expiration += STATE_ON_TIME_WINDOW;
      }


      // if (WG_debug) console.debug("Updating Status. next_comm_expiration", {last_comm: device.last_comm, current_time: _current_time, next_comm: _next_comm, next_comm_expiration: next_comm_expiration}, device);

      let _new_status = null;
      let missing_params = "";
      // Schedule status_update to when the sensor might change status
      let _next_status_update = 24 * 60 * 60 * 1000;

      // Easy/obvious state-changes first

      if (!_new_status && device.is_virtual) {
        _new_status = 'DEMO';
        _next_status_update = null;
        device.next_read = -1; // Hide
      }

      if (!_new_status && device.management_active === false) {
        _new_status = "DEACTIVATED";
        _next_status_update = null;
      }

      if (!_new_status && this.AuthService.isDemoAccount()) {
        _new_status = 'ON';
        _next_status_update = null;
      }

      if (!_new_status
          && device.model == "smartbox"
          && _lkm['PING']?.payload?.timestamp
          && _lkm['PING'].payload.timestamp - (device.time_drift || 0) > device.last_comm + this.ALLOWED_TIME_DRIFT) {
        // PING was the last command sent to a smartbox

        if (device.time_drift
            && _lkm['PONG']?.payload?.timestamp
            && _lkm['PONG'].payload.timestamp >= _lkm['PING'].payload.timestamp - device.time_drift) {
          console.warn('Smartbox with a time-drift detected! Pong was on time after drift correction: ',
              {ping: _lkm['PING']?.payload?.timestamp, drift: device.time_drift, pong: _lkm['PONG'].payload.timestamp});
        } else if (_current_time > _lkm['PING'].payload.timestamp + this.PING_TIMEOUT) {
          // it didn't answer within PING_TIMEOUT with a PONG (which updates last_comm)
          _new_status = "OFF";
        } else {
          // Still waiting for PONG or Timeout
          _new_status = device.status; // Don't allow to change status before PONG arrives-or-not
          next_comm_expiration = _lkm['PING'].payload.timestamp + this.PING_TIMEOUT;
          _next_status_update = next_comm_expiration - _current_time;
        }
      }

      if (!_new_status) {
        if (_current_time > next_comm_expiration) {
          _new_status = "OFF";
        } else {
          // Schedule check to when this device will be Off.
          _next_status_update = Math.min(_next_status_update, next_comm_expiration - _current_time);
        }
      }

      // non-obvious, non-instantaneous state-changes now
      if (!_new_status) {
        let all_required_params_arrived = true; // Within 30s of last_read
        let any_param_missing_longtime = false; // Within 5 sample intervals

        // check if all required measurements have arrived
        if (device.model
            && REQUIRED_PARAMS_PER_MODEL[this.AuthService.user.role]) {
          _.forEach(REQUIRED_PARAMS_PER_MODEL[this.AuthService.user.role], (_streams, _model) => {
            // if (device.sn == '3700fa') {
            //   console.log("Testing!!");
            // }
            if (!device.model.toLowerCase().startsWith(_model)) {
              return; // continue
            }
            // Model matches. Check against required list of streams
            for (let _or_streams of _streams) {
              if (!_.isArray(_or_streams)) {
                _or_streams = [_or_streams];
              }
              let _present = false;
              let _most_recent = 0;
              for (let _stream of _or_streams) {
                if (device.lkm[_stream]?.timestamp) {
                  _most_recent = Math.max(_most_recent, device.lkm[_stream].timestamp);
                  if (device.lkm[_stream].timestamp > device.last_read - ALLOWED_PROCESSING_DELAY) {
                    _present = true;
                    break;
                  }
                }
              }
              if (_present) {
                continue;
              }

              if (_most_recent < device.last_read - device.sample_interval * 2.1) {
                any_param_missing_longtime = true;
              }

              // None present. Possibly FAULT. Save names
              all_required_params_arrived = false;

              if (_.size(missing_params) > 0)
                missing_params += ", ";
              else {
                missing_params = this.$translate.instant("app.measurement.status.MISSING") + " ";
              }

              let sensor = _.find(this.$rootScope.WGSensors.sensors, {stream: _or_streams[0]});
              if (sensor) {
                missing_params += this.$translate.instant(sensor.name_sref || sensor.name);
              } else {
                missing_params += _or_streams[0];
              }
            }
            return false;
          });
        }

        if (all_required_params_arrived) {
          _new_status = "ON";
        }

        if (!_new_status && !all_required_params_arrived) {
          if (_current_time > device.received_time + ALLOWED_PROCESSING_DELAY
              || (!device.status && any_param_missing_longtime)) {
            _new_status = "FAULT";
            if (WG_debug) {
              console.warn("FAULT device detected.", missing_params, device.owner?.username, device.sn);
              _.forEach(device.lkm, (value, key) => {
                if (!value) return;
                if (value.timestamp > device.last_read - ALLOWED_PROCESSING_DELAY) {
                  console.info("FAULT device received:", key, value);
                }
              });
            }
          } else {
            if (WG_debug) console.log("Device status unknown. Waiting for data.", missing_params);
            // Schedule check for missing sensors after processing delay
            _next_status_update = Math.min(_next_status_update, device.received_time + ALLOWED_PROCESSING_DELAY - _current_time);
          }
        }
      }

      if (!_new_status) {
        // if (WG_debug) console.warn("Not changing device status.", device.status, device.status_extra, device);
        // _new_status = "OFF";

        if (!device.status) { // Mark ON immediately if we just loaded the dashboard
          device.status = "ON";
        }
      }

      if (_new_status && _new_status != device.status) {
        if (WG_debug && device.status) console.warn(Highcharts.dateFormat('%H:%M:%S.%L', _current_time) + " Device changed status: " + device.status + " -> " + _new_status, device.owner.username, device.sn, device);
        device.status = _new_status;

        if (missing_params != device.status_extra) {
          device.status_extra = missing_params;
        }

        if (_new_status == 'OFF'
            && this.$rootScope.system_status.smartbox_offline == null
            && device.comm_status?.last_gw_id
            && this.$rootScope.WGDevices.devices_id[device.comm_status?.last_gw_id]?.status == 'OFF') {
          // Live update when a sensor becomes offline due to smartbox
          this.update_smartbox_status();
        } else if (_new_status == 'ON'
            && this.$rootScope.system_status.smartbox_offline != null) {
          // Live update when a sensor comes back online.
          this.update_smartbox_status();
        }
      }

      if (_next_status_update > 0) {
        this.update_device_status_soon(device, _next_status_update + 500, true);
        // if (WG_debug) console.log('update_device_status scheduled for id', device.id, _next_status_update + 500);
      } else if (!_.isNil(_next_status_update)) {
        if (WG_debug) console.log('Negative _next_status_update???', _next_status_update, device);
      }


      // Show "incoming"/"now" if next_read == [-3min, +30s]
      if (_current_time > device.next_read - 15 * 1000
          && _current_time < device.next_read + 4 * 60 * 1000) {
        device.next_read = 0; // Show "incoming"/"now"
      } else if (_current_time > device.next_read + 2 * 60 * 1000) { // Lost next_read. Device is probably Offline
        // Read is too late. don't show
        device.next_read = -1; // Hide
      }

      if (device.status != "DEACTIVATED" && device.capabilities.config_density_read) {
        device.status_level_reading = this.get_device_level_read_status(device);
        device.status_density_reading = this.get_device_density_read_status(device);
      }

      this.update_device_rotation_status(device);

      return device.status;
    }

    public update_smartbox_status() {
      this.$rootScope.system_status.smartbox_offline = null;
      let any_smartbox_online = false;
      let has_smartboxes = false;
      _.forEach(this.$rootScope.WGDevices.devices, (_device: IDevice) => {
        if (SMARTBOX_MODELS.includes(_device.model)) {
          has_smartboxes = true;

          // If all smartboxes are offline, show a warning
          if (_device.status == 'ON' || _device.status == 'DEMO') {
            any_smartbox_online = true;
          }
        } else { // non-smartbox
          // If any recent sensor currently Offline has the last smartbox also offline, show a warning. It might be due to the smartbox.
          if (_device.status == 'OFF'
              && (new Date().getTime()) - _device.last_read < 1 * 30 * 24 * 60 * 60 * 1000
              && this.$rootScope.WGDevices.devices_id[_device.comm_status?.last_gw_id]?.status == 'OFF') {
            this.$rootScope.system_status.smartbox_offline = this.$translate.instant('app.systemstatus.SMARTBOX_OFFLINE');
            return false;
          }
        }
      });
      if (has_smartboxes && !any_smartbox_online) {
        this.$rootScope.system_status.smartbox_offline = this.$translate.instant('app.systemstatus.SMARTBOX_OFFLINE');
      }
    }

    /**
     * Estimates the rotation angle of the device based on the ACC_XYZ/_TREAT values
     * @param device
     * @param acc_xyz
     * @param acc_xyz_treat
     * @returns [angle_side, angle_front, angle_total]
     */
    public get_device_rotation_angles(device: IDevice, acc_xyz: number[], acc_xyz_treat: object): [side: number, front: number, total: number] {

      let _xyz_data: {
        reference?: number[], // Taken during calibration
        data?: number[], // Current ACC values
        value?: number[], // Difference between data and reference
        angles?: number[], // Estimated angles
      } = _.cloneDeep(acc_xyz_treat) || {};

      if (_.isEmpty(_xyz_data.data) && acc_xyz) {
        _xyz_data.data = _.cloneDeep(acc_xyz);
      }

      // WP standard axis:
      // X - Pointing down
      // Y - Pointing to the right
      // Z - Pointing to the front

      // aphrometer and smartbung axis:
      // X - Pointing down
      // Y - Pointing to the front
      // Z - Pointing to the right

      // smartcellar axis:
      // X - Pointing to the front
      // Y - Pointing to the right
      // Z - Pointing down

      // WPs, BPs, etc, axis configuration. Reference is given from callibration
      let max_acc = 0;
      let vertical_axis = 0;
      let side_axis = 1;
      let front_axis = 2;

      if (_.isEmpty(_xyz_data.reference)) {
        if (['smartbung'].includes(device.model)) {
          _xyz_data.reference = [4096, 150, 500];
          max_acc = 4096;
          vertical_axis = 0;
          side_axis = 2;
          front_axis = 1;

        } else if (['aphrometer'].includes(device.model)) {
          _xyz_data.reference = [4096, -0, 0];
          max_acc = 4096;
          vertical_axis = 0;
          side_axis = 2;
          front_axis = 1;

        } else if (['smartcellar'].includes(device.model)) {
          _xyz_data.reference = [0, 0, 4096];
          max_acc = 4096;
          vertical_axis = 2;
          side_axis = 1;
          front_axis = 0;
        }
      }

      if (_.isEmpty(_xyz_data.value) && !_.isEmpty(_xyz_data.data) && !_.isEmpty(_xyz_data.reference)) {
        _xyz_data.value = [
          _xyz_data.data[0] - _xyz_data.reference[0],
          _xyz_data.data[1] - _xyz_data.reference[1],
          _xyz_data.data[2] - _xyz_data.reference[2],
        ];
      }

      if (_.isEmpty(_xyz_data.reference) || _.isEmpty(_xyz_data.data) || _.isEmpty(_xyz_data.value)) {
        // if (WG_debug) console.warn("Some reference/data is missing to estimate rotation", device.model, _xyz_data, device);
        return null;
      }

      // Automatically find the axis configuration from provided reference if not defined
      if (!max_acc) {
        max_acc = Math.abs(_xyz_data.reference[0]);
        if (Math.abs(_xyz_data.reference[1]) > max_acc) {
          max_acc = Math.abs(_xyz_data.reference[1]);
          vertical_axis = 1;
          side_axis = 0;
          front_axis = 2;
        }
        if (Math.abs(_xyz_data.reference[2]) > max_acc) {
          max_acc = Math.abs(_xyz_data.reference[2]);
          vertical_axis = 2;
          side_axis = 1;
          front_axis = 0;
        }
      }

      if (WG_debug && max_acc < 3000)
        console.warn("No vertical axis? Accelerometer not horizontal?!?!");


      // Calculate the dot product between the initial and rotated vectors.
      const dotProduct =
          _xyz_data.reference[0] * _xyz_data.data[0] +
          _xyz_data.reference[1] * _xyz_data.data[1] +
          _xyz_data.reference[2] * _xyz_data.data[2];

      // Calculate the magnitudes (lengths) of the initial and rotated vectors.
      const initialMagnitude = Math.sqrt(
          _xyz_data.reference[0] * _xyz_data.reference[0] +
          _xyz_data.reference[1] * _xyz_data.reference[1] +
          _xyz_data.reference[2] * _xyz_data.reference[2]
      );
      const rotatedMagnitude = Math.sqrt(
          _xyz_data.data[0] * _xyz_data.data[0] +
          _xyz_data.data[1] * _xyz_data.data[1] +
          _xyz_data.data[2] * _xyz_data.data[2]
      );
      // Calculate the cosine of the angle between the vectors using the dot product.
      const cosTheta = dotProduct / (initialMagnitude * rotatedMagnitude);
      // Calculate the angle in radians using the inverse cosine (arccos).
      const angleInRadians = Math.acos(cosTheta);
      // Convert the angle from radians to degrees
      const totalAngleInDegrees = (angleInRadians * 180.0) / Math.PI;

      // Return angles of rotation between vertical and side axis, vertical and front axis, and total
      return [
        Math.atan2(Math.abs(_xyz_data.value[side_axis]), max_acc - Math.abs(_xyz_data.value[vertical_axis])) * 180.0 / Math.PI,
        Math.atan2(Math.abs(_xyz_data.value[front_axis]), max_acc - Math.abs(_xyz_data.value[vertical_axis])) * 180.0 / Math.PI,
        Math.abs(totalAngleInDegrees),
      ];
    }

    private update_device_rotation_status(device: IDevice) {
      if (device?.status == "DEACTIVATED") {
        device.rotation_status = null;
        return;
      }
      if (!device?.lkm || !device.lkm['ACC_XYZ']?.payload?.value) {
        // if (WG_debug) console.warn("No ACC_XYZ_TREAT payload to estimate rotation");
        device.rotation_status = null;
        return;
      }

      let angles: [side: number, front: number, total: number] = this.get_device_rotation_angles(device, device.lkm['ACC_XYZ']?.payload?.value, device.lkm['ACC_XYZ_TREAT']?.payload?.value);
      if (_.isEmpty(angles)) {
        return;
      }

      // if (WG_debug) {
      //   console.log("Rotation estimated for %s, %s", device.unit?.name || device.name, device.sn, {
      //     angle_total: angles[2],
      //     angle_side: angles[0],
      //     angle_front: angles[1]
      //   });
      // }

      if (!device.rotation_status) {
        device.rotation_status = {
          angle: 0,
          icon: "icon-no_tilt",
          status: "OK",
          status_sref: "",
          status_extra_sref: "",
          status_extra_2_sref: "",
          admin_status_extra: "",
        };
      }
      // device.rotation_status.status_extra_sref = null;
      // device.rotation_status.status_extra_2_sref = null;
      // device.rotation_status.admin_status_extra = null;

      device.rotation_status.angles = angles;

      // Cliente !	Sensor inclinado. Diferenças de densidade superiores a +-3 g/cm3 expectáveis.
      // X: [X_ref - 150; X_ref + 150]
      // Y: [Y_ref - 500; Y_ref + 500]
      // Z: Ignorar (Cliente não fará nada porque só mudando a inclinação do din)
      //
      // Cliente ! GRAVE! Sensor Muito inclinado. Diferenças de densidade superiores a +-5 g/cm3 expectáveis.
      // X: [X_ref - 150; X_ref + 150]
      // Y: [Y_ref - 800; Y_ref + 800]
      // Z: Ignorar (Cliente não fará nada porque só mudando a inclinação do din)
      //
      // Apenas Winegrid Admins	!	DIN inclinado (Erro superiores a +-3 g/cm3)
      // TotalAngle > 5º

      let warn_level = 5;
      let error_level = 10;
      if (['smartbung'].includes(device.model)) {
        warn_level = 10;
        error_level = 20;
      } else if (['aphrometer'].includes(device.model)) {
        warn_level = 60;
        error_level = 60;
      } else if (['smartcellar'].includes(device.model)) {
        warn_level = 999; // Immune to rotations
        error_level = 999;
      }

      if (angles[2] >= 120) {
        device.rotation_status.angle = 180;
        device.rotation_status.icon = "icon-sideways";
        device.rotation_status.status_extra_sref = "app.devices.rotation.status.UPSIDEDOWN";
        if (['smarcellar'].includes(device.model)) {
          device.rotation_status.status = "OK";
        } else {
          device.rotation_status.status = "WARN";
          device.rotation_status.status_extra_2_sref = "app.devices.rotation.UPSIDEDOWN_DANGER";
        }
      } else if (angles[2] >= 75) {
        device.rotation_status.angle = 90;
        device.rotation_status.icon = "icon-sideways";
        device.rotation_status.status = "OK";
        device.rotation_status.status_extra_sref = "app.devices.rotation.status.SIDEWAYS";
        if (['aphrometer'].includes(device.model)) {
          device.rotation_status.status = "ERROR";
          device.rotation_status.status_extra_2_sref = "app.devices.rotation.APHROM_DANGER";
        }
        // device.rotation_status.admin_status_extra = "Sideways";
      } else if (angles[2] >= error_level) {
        device.rotation_status.angle = 25;
        device.rotation_status.icon = "icon-high_tilt";
        device.rotation_status.status = "ERROR";
        device.rotation_status.status_extra_sref = "app.devices.rotation.status.HIGH_ROTATION";
        device.rotation_status.status_extra_2_sref = "app.devices.rotation.VERYLOW_ACCURACY";
        if (['aphrometer'].includes(device.model)) {
          device.rotation_status.status_extra_2_sref = "app.devices.rotation.APHROM_DANGER";
        }

      } else if (angles[2] >= warn_level) {
        device.rotation_status.angle = 10;
        device.rotation_status.icon = "icon-low_tilt";
        device.rotation_status.status = "WARN";
        device.rotation_status.status_extra_sref = "app.devices.rotation.status.SOME_ROTATION";
        device.rotation_status.status_extra_2_sref = "app.devices.rotation.REDUCED_ACCURACY";
      } else {
        device.rotation_status.angle = 0;
        device.rotation_status.icon = "icon-no_tilt";
        device.rotation_status.status = "OK";
      }

      // DIN rotations, for admins only
      // if (!device.rotation_status.admin_status_extra && Math.abs(angles[1]) > 2) {
      //   // device.rotation_status.admin_status_extra = this.$translate.instant("app.device.status.DIN_ROTATION_ERROR");
      //   device.rotation_status.admin_status_extra = "DIN not vertical!";
      // } else {
      //   device.rotation_status.admin_status_extra = null;
      // }
      switch (device.rotation_status.status) {
        case "WARN":
          device.rotation_status.status_sref = "app.common.WARNING";
          break;
        case "ERROR":
          device.rotation_status.status_sref = "app.common.DANGER";
          break;
        case "OK":
          device.rotation_status.status_sref = "app.common.OK";
          break;
        default:
          device.rotation_status.status_sref = "";
      }

      return;
    }


    /**
     * Get sensor value from LKM given configs
     */
    public get_value_from_lkm(lkm_payload: IDataResult, sensor: ISensor, sub_query: string | object = null, device: IDevice = null): number | string {
      let _this_val;

      if (!_.isEmpty(sub_query)) {
        if (!_.isNil(lkm_payload.value)) {
          _this_val = select(sub_query, lkm_payload.value)[0];
          if (Array.isArray(_this_val) && _this_val.length >= 1) {
            _this_val = _this_val[0]; // e.g. MESHVINES_SIMULATOR_dens_boulton
          }
          if (typeof _this_val == 'number' || typeof _this_val == 'string') {
            // Value available with sub_query, as should
            return _this_val;
          }
        }

        // Try with sub_query over Payload. e.g.: sleep.infos.retry_count
        _this_val = select(sub_query, lkm_payload)[0];
        if (typeof _this_val == 'number' || typeof _this_val == 'string') {
          if (WG_debug && !["SLEEP_infos_retry_count"].includes(sensor.internal_name)) {
            console.warn('Sub_query found over payload!!', {
              sub_query: sub_query,
              lkm_payload: lkm_payload,
              sensor: sensor,
              device: device
            });
          }
          return _this_val;
        }

      }

      // Try without sub_query...
      _this_val = lkm_payload.value;
      if (Array.isArray(_this_val) && _this_val.length === 1) {
        _this_val = _this_val[0];
      }

      if (typeof _this_val == 'number' || typeof _this_val == 'string') {
        return _this_val;
      }
      // if (WG_debug) console.warn('Sub_query and payload.value failed!', {val: _this_val, sub_query: sub_query, lkm_payload: lkm_payload, sensor: sensor, device:device});
      return null;
    }

    /**
     * @param internal_name sensor name to generate
     * @returns Object with the minimum required parameter-values
     */
    public get_sensor_default_values(internal_name: string): ISensorReading {
      let _sensor = this.$rootScope.WGSensors.sensors_name[internal_name];
      if (!internal_name || !_sensor) {
        if (WG_debug) console.warn("This sensor doesn't exist!", internal_name);
      }
      return {
        sensor: _sensor,
        internal_name: internal_name,
        timestamp: 0,
        val_orig: undefined,
        val: undefined,
        val_numeric: undefined,
        status: 'OK',
      }
    }

    /**
     * Returns a "#RRGGBB" string-color based on the sensor value
     */
    public get_value_color(device: IDevice, sensor: ISensor, value: number): string {
      let color: string = null;
      if (sensor.internal_name === "CO2") {
        if (value < 1000)
          return rgbToHex(100, 255, 100);
        if (value < 1500)
          return rgbToHex(255, 255, 100);
        if (value < 2000)
          return rgbToHex(255, 200, 100);
        if (value < 3000)
          return rgbToHex(255, 100, 100);
        return rgbToHex(180, 0, 0);
      }

      if (sensor.internal_name.startsWith("MESHVINES_SIMULATOR")) {
        if (sensor.internal_name.endsWith("_Maintenance")) {
          // Maintenance:  Range 0.5 - 0.25, Red: 0.5-0.8, Yellow: 0.8-0.1, Green: 0.1-0.25
          if (value < 0.08)
            return 'rgb(220,0,0,0.5)';
          if (value < 0.1)
            return 'rgb(220,220,0,0.5)';
          return 'rgb(0,200,0,0.5)';
        }
        if (sensor.internal_name.endsWith("_Lag")) {
          // Lag: Green: 0-50, Yellow: 50-80, Red: >80
          if (value < 50)
            return 'rgb(0,200,0,0.5)';
          if (value < 80)
            return 'rgb(220,220,0,0.5)';
          return 'rgb(220,0,0,0.5)';
        }

        if (sensor.internal_name.endsWith("_InitialNitrogen")) {
          // Nitrogen: Range: 100-300, Red 100-130, Yellow: 130-160, Green: 160 -300
          if (value < 130)
            return 'rgb(220,0,0,0.5)';
          if (value < 160)
            return 'rgb(220,220,0,0.5)';
          return 'rgba(0,200,0,0.5)';
        }

        if (sensor.internal_name.endsWith("_Viability")) {
          // Viability: Range: 8 - 60.  Red: 8-20, Yellow: 20-40, Green: 40-60
          if (value < 20)
            return 'rgb(220,0,0,0.5)';
          if (value < 40)
            return 'rgb(220,220,0,0.5)';
          return 'rgb(0,200,0,0.5)';
        }

        if (sensor.internal_name.endsWith("_EthInh")) {
          // Ethanol Inhibition Constant: Range: 0.025-0.045, Green: 0.025-0.037,Yellow: 0.037-0.042,Red: 0.042-0.045
          if (value < 0.037)
            return 'rgb(0,200,0,0.5)';
          if (value < 0.042)
            return 'rgb(220,220,0,0.5)';
          return 'rgb(220,0,0,0.5)';
        }
      }
      return color;
    };

    /**
     * Gets the objects from the global list of LSVs
     * @param sensor_internal_names List of sensor names to get
     * @param device_UUID device UUID
     * @param allow_no_values Returns list of sensors, even if the device's LKM doesn't have it.
     * @param force_allowed_sensors List of sensors to allow, even if the user doesn't have access to it.
     * @returns {[]} - Array of sensor-objects, populated with last values and other fields
     */
    public get_last_sensors_values(sensor_internal_names: string[], device_UUID: string, allow_no_values = false, force_allowed_sensors = []): ISensorReading[] {
      if (WG_debug) console.debug("Data-Utils. get_last_sensors_values");
      if (typeof device_UUID !== 'string' && !allow_no_values) {
        if (WG_debug) console.error("Wrong type of UUID!", device_UUID);
      }
      let ret = [];

      let _device_lsv = this.$rootScope.WGDevices.devices_uuid[device_UUID]?.last_values;
      if (!_device_lsv && !allow_no_values) { // No LSV for the device!?!?!?
        return ret;
      }
      if (!sensor_internal_names || sensor_internal_names.length === 0) {
        sensor_internal_names = Object.keys(this.$rootScope.WGSensors.sensors_name);
      }
      _.forEach(sensor_internal_names, (sensor_internal_name) => {
            if (sensor_internal_name == 'MANUAL_Entries') {
              return;
            }
            if (!force_allowed_sensors.includes(sensor_internal_name) &&
                !this.canUserAccessSensor(sensor_internal_name)) {
              return;
            }
            if (_device_lsv?.[sensor_internal_name]) {
              ret.push(_device_lsv[sensor_internal_name]);
            } else {
              if (allow_no_values) {
                ret.push(this.get_sensor_default_values(sensor_internal_name));
              }
            }
          }
      );

      if (WG_debug) console.log("DataUtils.last_values returning:", ret)
      return ret;
    }

    public can_device_force_read(device: IDevice) {
      if (!device || !device.model || !device.internal_name || device.management_active === false) {
        return false;
      }

      if (device.configs) {
        if (device.configs.can_force_read === true || device.configs.force_read === true) {
          return true;
        }
        if (device.configs.can_force_read === false || device.configs.force_read === false) {
          return false;
        }
      }

      let _internal_name_upper = device.internal_name.toUpperCase();

      if (_internal_name_upper.startsWith('DENS_LAT_19_')
          || _internal_name_upper.startsWith('DENS_LAT_2')
          || _internal_name_upper.startsWith('DEV_VOL_2')
          || _internal_name_upper.startsWith('DEV_CHARMAT')) {
        return true;
      }

      if (DEVICE_MODELS_THAT_CAN_FORCE_READ.includes(device.model)) {
        return true;
      }

      return false;
    }

    /** True if any device can enable/disable density reading
     *
     * @param device - device with .model, .internal_name and .configs  fields
     * @returns true if the given device can perform Force a Read operation
     */
    public can_device_change_density_read(device: Partial<IDevice>) {
      if (!device || !device.model || !device.internal_name || !device.uuid || !device.iid || !device.path || device.management_active === false)
        return false;
      let _internal_name_upper = device.internal_name.toUpperCase();

      if (_internal_name_upper.startsWith('DENS_LAT_19_')
          || _internal_name_upper.startsWith('DENS_LAT_2')
          || _internal_name_upper.startsWith('DENS_BP_')
          || _internal_name_upper.startsWith('WP1')) {
        return true;
      }

      if (DEVICE_MODELS_THAT_CAN_FORCE_READ.includes(device.model)) {
        return true;
      }

      return false;
    }

    /** True if any device can set_to_0/reset some offset, such as LLV_TREAT and TOTALIZER
     *
     * @param device - device with .model, .internal_name and .configs  fields
     * @returns true if the given device can perform action
     */
    public can_device_set_offsets(device: Partial<IDevice>) {
      // if (!device || !device.model || !device.internal_name || !device.uuid || !device.iid || !device.path || device.management_active === false) {
      if (!device || !device.model || !device.internal_name || !device.uuid || !device.iid || !device.path) {
        return false;
      }

      if (device.lkm?.['PRESS_VOLUME_TOTALIZER_A'] ||
          device.lkm?.['LLV_TREAT'] ||
          device.last_known_message?.['PRESS_VOLUME_TOTALIZER_A'] ||
          device.last_known_message?.['LLV_TREAT']) {
        return true;
      }

      return false;
    }

    /**
     * True if device(s) can configure their used WiFi ssid/password
     *
     * @param device - device with .model field, or .id and got from WGDevices.devices_id
     * @returns true if the given device can perform action
     */
    public can_device_config_connection(device: Partial<IDevice> | Partial<IDevice>[]) {
      if (!device) {
        return false;
      }
      if (_.isArray(device)) {
        let ret = false;
        _.forEach(device, (d) => {
          if (this.can_device_config_connection(d)) {
            ret = true;
            return false;
          }
        });
        return ret;
      }
      if (!device.id) {
        return false;
      }
      let model = device.model;
      if (!model) {
        model = this.$rootScope.WGDevices?.devices_id?.[device.id]?.model;
      }
      if (!model) {
        return false;
      }
      if (['smartbung', 'smartcellar', 'aphrometer', 'ultrasonic'].includes(model)) {
        return true;
      }
      // if (['SPECTRAL'].includes(model)) {
      //   return true;
      // }

      return false;
    }

    /**
     * True if the device can set RGB alarms
     *
     * @param device - device with .model field, or .id and got from WGDevices.devices_id
     * @returns true if the given device can perform action, false otherwise
     */
    public can_device_set_RGB(device: Partial<IDevice> | Partial<IDevice>[]) {
      // if (!device || !device.model || !device.internal_name || !device.uuid || !device.iid || !device.path || device.management_active === false) {
      if (!device) {
        return false;
      }
      if (_.isArray(device)) {
        let ret = false;
        _.forEach(device, (d) => {
          if (this.can_device_set_RGB(d)) {
            ret = true;
            return false;
          }
        });
        return ret;
      }
      if (!device.id) {
        return false;
      }

      let model = device.model || this.$rootScope.WGDevices?.devices_id?.[device.id]?.model;
      if (model) {
        if (['wp1110'].includes(model)) {
          return true;
        }
      }

      let configs = device.configs || this.$rootScope.WGDevices?.devices_id?.[device.id]?.configs;
      if (configs) {
        if (configs.alarms_actions?.neo_pixel_rgb) {
          return true;
        }
      }

      return false;
    }

    // True if device is configured to read density (has "ldensa_n_reads" != 0)
    public get_device_level_read_status(device: IDevice): boolean {
      if ((device.capabilities && !device.capabilities.config_density_read)
          || (!device.capabilities && !this.can_device_change_density_read(device))) {
        return true;
      }
      let _configs: IDeviceLKMConfigs = parseData(device.lkm?.configs?.payload?.value, {});
      // Configured as Disabled, allow only to_enable
      if (_configs.ldensa_n_reads === 0) {
        return false;
      }
      return true;
    }

    // TODO: Implement
    // False if device is in Pneumatic Lockdown
    public get_device_pneumatic_system_status(device: IDevice): boolean {
      let _configs: IDeviceLKMConfigs = parseData(device.lkm?.configs?.payload?.value, {});
      if (!_.isNil(_configs.disable_functions) && bit_test(_configs.disable_functions, DISABLE_FUNCTIONS_BITS["DISABLE_PNEUMATIC_SYSTEM"])) {
        return false;
      }
      return true;
    }

    // False if Backflow Protection system is disabled
    public get_device_backflow_protection_status(device: IDevice): boolean {
      let _configs: IDeviceLKMConfigs = parseData(device.lkm?.configs?.payload?.value, {});
      if (!_.isNil(_configs?.disable_functions) && bit_test(_configs.disable_functions, DISABLE_FUNCTIONS_BITS["DISABLE_BACKFLOW_PROTECTION"])) {
        return false;
      }
      return true;
    }

    // True if device is configured to read density
    public get_device_density_read_status(device: IDevice): boolean {
      if ((device.capabilities && !device.capabilities.config_density_read)
          || (!device.capabilities && !this.can_device_change_density_read(device))) {
        return true;
      }
      let _configs: IDeviceLKMConfigs = parseData(device.lkm?.configs?.payload?.value, {});
      if (!_.isNil(_configs.disable_functions) && bit_test(_configs.disable_functions, DISABLE_FUNCTIONS_BITS["DISABLE_DENSITY"])) {
        // || bit_test(_configs.disable_functions, DISABLE_FUNCTIONS_BITS["DISABLE_PNEUMATIC_SYSTEM"])
        return false;
      }
      // Legacy
      if (!_.isNil(_configs.ldensa_n_reads) && _configs.ldensa_n_reads <= 1) {
        return false;
      }
      return true;
    }

    // True if any device can set the density_read to desired ON/OFF state
    /**
     * @param device - device with .model, .internal_name and .configs  fields
     * @param to_enable - If we want to activate, or deactivate
     * @returns true if the given device can perform Force a Read operation
     */
    public can_device_config_density_read(device: IDevice, to_enable = false) {
      if (!this.can_device_change_density_read(device))
        return false;

      // Configured as Disabled, allow only to_enable
      if (!this.get_device_density_read_status(device)) {
        // Disabled
        return to_enable;
        // Already off
      }
      return !to_enable;
      // Already on
    }

    public has_device_AI_LDENSA(device: IDevice) {
      if (!device || !device.model || !device.internal_name)
        return false;

      // Only these models report density
      if (!DEVICE_MODELS_THAT_HAS_DENSITY.includes(device.model)) {
        return false;
      }
      if (device.lkm?.['AI_LDENSA']?.payload?.value) {
        return true;
      }
      return false;
    }


    /**
     * Pre-process data globally, fixing known data-bugs, preforming non-optional conversions, normalizing timestamps, etc.
     * @param sensor
     * @param data - single point or array of points
     * @param device
     * @return New array with preprocessed data
     */
    public preprocess_data(sensor: ISensor, data: any[], device: IDevice = null): any[] {
      let is_matrix = _.size(data[0]) >= 2;
      if (!is_matrix && _.isArray(data[0])) {
        if (WG_debug) console.error("Not a matrix nor a simple array. What?", data);
      }

      // Don't allow positive RSSI values
      //# If positive [0, 127], it's a signed int8 overflow, since RSSI should always be negative
      // if 0 < self._radioRSSI < 128:
      //     self._radioRSSI = -256 + self._radioRSSI
      if (['WIFI_RSSI', 'LORA_INFOS_RSSI', 'LORA_RSSI'].includes(sensor.internal_name)) {
        data = _.map(data, (e) => {
          if (is_matrix) {
            if (e[1] > 0 && e[1] < 128) {
              e[1] = -256 + e[1];
            }
          } else {
            if (e > 0 && e < 128) {
              e = -256 + e;
            }
          }
          return e;
        });
      }

      // Convert from ms to s
      if (sensor.internal_name === 'TBOOT') {
        data = _.map(data, (e) => {
          if (is_matrix) {
            if (_.isFinite(e[1])) {
              if (e[1] > 90000000000) // Too big, epoch must be in ms
                e[1] /= 1000;
            }
          } else {
            if (_.isFinite(e)) {
              if (e > 90000000000)
                e /= 1000;
            }
          }
          return e;
        });
        if (sensor.unit === 'ms') {
          sensor.unit_orig = 'ms';
          sensor.unit = 's';
        }
      }
      // Convert from ms to s
      if (sensor.internal_name === 'VPROG') {
        let preprocess_VPROG = (val) => {
          if (typeof val === 'string') {
            //remove any non-number characters from string
            val = val.replace(/[^0-9.]/g, ''); // Remove any non-number characters from string
            // remove any . appearing anywhere after the first dot, leaving all other characters
            val = val.replace(/^(.*?\..*?)\./, '$1'); // Remove any . appearing anywhere after the first dot
          }
          return Number(val); // Convert to number
        }

        data = _.map(data, (e: string | any[] | number) => {
          // if (WG_debug) console.log('converting VPROG', e);
          if (is_matrix) {
            e[1] = preprocess_VPROG(e[1]);
          } else {
            e = preprocess_VPROG(e);
          }
          // if (WG_debug) console.log('converted VPROG to', e);
          return e;
        });
      }

      if (sensor.internal_name === 'WAKEUP_REASON') {
        let preprocess_WAKEUP_REASON = (val) => {
          if (!_.isFinite(val)) return;
          if (!this.AuthService.user_view_as_admin) {
            // Don't show WAKEUP_REASON for some device models
            if (device?.model?.startsWith('wp11')) return;
            if (device?.model?.includes('charma')) return;

            if (val == 0) return 1; // 0	Unknown - Never show 0-Unknown for clients. Disguise as "Powered on"
            // if (val == 1) return; // 1	Powered on
            // if (val == 2) return; // 2	Handled
            // if (val == 3) return; // 3	Button pressed
            // if (val == 4) return; // 4	Config button
            if (val == 5) return; // 5	Timer
            if (val == 6) return 1; // 6	Watchdog - Disguise "watchdog" as "Powered on" for users
            if (val == 7) return 1; // 7	SW Watchdog - Disguise "watchdog" as "Powered on" for users
            if (val == 8) return 1; // 8	CTRL Watchdog - Disguise "watchdog" as "Powered on" for users
            if (val == 9) return; // 9	Timer/Force_Read
            if (val == 10) return 1; // 10	Heap Watchdog - Disguise "watchdog" as "Powered on" for users
            if (val == 11) return; // 11	Reboot
          } else {
            if (device?.model?.startsWith('wp11')) {
              if (val == 0) return 1;// WPs send a 0 after power cycle.
            }
          }
          return val;
        }

        if (is_matrix) {
          data = _.map(data, (e: string | number) => {
            e[1] = preprocess_WAKEUP_REASON(e[1]);
            return e;
          });
          // Remove null entries:
          _.remove(data, (e) => {
            return e?.[1] == null;
          });
        } else {
          data = _.map(data, (e: string | number) => {
            e = preprocess_WAKEUP_REASON(e);
            return e;
          });
          // Remove null entries:
          _.remove(data, (e) => {
            return e == null;
          });
        }
      }


      return data;
    }

    /**
     *  0 =	Unknown,
     *  1 =	Powered on,
     *  2 =	Handled,
     *  3 =	Button pressed,
     *  4 =	Config button,
     *  5 =	Timer,
     *  6 =	Watchdog,
     *  7 =	SW Watchdog,
     *  8 =	CTRL Watchdog,
     *  9 =	Timer/Force_Read,
     *  10 = Heap Watchdog,
     *  11 = Reboot
     * @param value
     * @param device
     */
    public convert_wakeup_reason(value: number, device: IDevice = null): string {
      if (device?.model?.startsWith('wp11')) {
        if (value == 9) // WPs send a 9 after a Force_Read with Sleep=0.
          return this.$translate.instant('sensors.handlings.val_COMMAND');
      }
      if (!this.AuthService.user_view_as_admin) {
        if (value == 11) {
          // Show "Reboot" as "Command" for users
          return this.$translate.instant('sensors.handlings.val_COMMAND');
        }
      }
      if (value >= 0 && value <= 11) {
        return this.$translate.instant('sensors.handlings.val_' + value);
      }
      return 'Unknown';
    }
  }

  App.service('DataUtils', DataUtils);
}