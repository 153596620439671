namespace wg {

  export class WgProtocolDetailed {

    static $inject = ['ngDialog', '$scope', '$timeout', '$rootScope', '$http', '$state'];

    public protocol: IWinemakingProtocol;
    public stagesSequence: IWinemakingStage[];

    public readonly: boolean = false;
    public loaded: boolean = false;

    public WINE_TYPES = wg.WINE_TYPES;

    //Maps stage to its header tasks
    //Header tasks are tasks that have no dependencies, we start with them to build the sequence and display them in order
    public headerTasks: { stageId: ITask[] } = {} as any;

    constructor(private ngDialog: any,
                private $scope: ng.IScope,
                private $timeout: ng.ITimeoutService,
                private $rootScope: any,
                private $http: ng.IHttpService,
                private $state: _IState) {
      if (WG_debug) console.log("constructor wg-protocol-detailed", this);
      $scope.$watch<IWinemakingProtocol>('ctrl.protocol', (newVal, oldVal) => {
        if (newVal) {
          if (WG_debug) console.log("wg-protocol-detailed, protocol changed", newVal);
          this.readonly = (newVal?.owner?.id !== this.$rootScope.AuthService?.view_as_owner?.id);
          this.buildHeaderTasks(newVal);
          this.buildStageSequence(newVal);
        }
      }, true)

    }

    $onInit() {
      if (WG_debug) console.log("$onInit wg-protocol-detailed", this);
      this.buildHeaderTasks(this.protocol);
      this.loaded = true;
    }

    $onChanges(changes) {
      //rebuild header tasks
      this.buildHeaderTasks(changes.protocol?.currentValue);
      if (WG_debug) console.log("$onChanges wg-protocol-detailed", this.headerTasks);
    }


    public createEditStage(stage: IWinemakingStage = null) {
      let last_stage = _.last(this.protocol.stages) || null;
      this.ngDialog.openConfirm({
        template: 'app/views/modals/create-or-edit-stage.html',
        data: {protocol: this.protocol, stage: stage, last_stage: this.stagesSequence[this.stagesSequence.length - 1]}
      }).then((new_stage: IWinemakingStage) => {
        if (!this.protocol.stages) this.protocol.stages = [];
        // this.normalizeStageInfo(new_stage);
        let _stage = _.find(this.protocol.stages, {id: new_stage.id});
        if (_stage) {
          _.assign(_stage, new_stage);
        } else {
          this.protocol.stages.push(new_stage);
        }
        // this.buildHeaderTasks(this.protocol);

        // For now, refresh state, as it might have changed other stages due to dependencies
        this.$state.reload();
      }).catch(() => {
      }).finally(() => {
          }
      );
    }


    public deleteStage(stage) {
      this.$http.delete<any>(`api/dashboard/winemaking/stage_template/${stage.id}/`).then((response) => {
        console.log("Stage deleted", response);
        if (response.status >= 200 && response.status < 300) {
          _.remove(this.protocol.stages, (s) => s.id == stage.id);
          // this.buildHeaderTasks(this.protocol);

          // For now, refresh state
          // this.$state.reload();
        }
      })

    }


    public createTask(stage: IWinemakingStage, ctrl: WgProtocolDetailed) {
      this.ngDialog.openConfirm({
        template: 'app/views/modals/create-or-edit-task.html',
        controller: ['$scope', 'ngDialog', function ($scope, ngDialog) {
          $scope.stage = stage;
        }],
      }).then(({addedTasks}: { addedTasks: ITask[] }) => {

        if (!stage.tasks) stage.tasks = [];

        stage.tasks.push(...addedTasks);

        // TODO emit a list instead of one by one
        // this.buildHeaderTasks(this.protocol);
        // addedTasks.forEach(t => {
        //   this.$rootScope.$emit('reloadTasks', t);
        // })


        // // For now, refresh state
        // this.$state.reload();
      }).catch(() => {

      });
    }


    public getTaskById(id: number, inStage: IWinemakingStage): ITask {
      return inStage.tasks?.find(t => t.id == id);
    }

    public taskHasDependency(task: ITask): boolean {
      if (!task) return false;
      if (!task.rules) return false;
      const dependency = task.rules.find(rule => rule.previousTask);
      return !!dependency;
    }

    public taskHasNonUniqueDependency(stage: IWinemakingStage, task: ITask): boolean {
      if (!task) return false;
      if (!task.rules) return false;
      let too_many_collisions = false;

      const rules_with_previousTask = task.rules.filter(rule => rule.previousTask);
      _.forEach(rules_with_previousTask, (rule) => {
        // Check how many tasks have rules with the same previousTask
        const dependencies = stage.tasks.filter(t => {
          // if (t == task) return false;
          if (!t.rules) return false;
          return t.rules.find(r => r.previousTask == rule.previousTask);
        });
        if (_.size(dependencies) > 1) {
          console.log("Task has non-unique dependency", task, dependencies);
          too_many_collisions = true;
          return false;
        }
      });
      return too_many_collisions;
    }

    /*
    * Picks all tasks that have no dependencies and adds them to the headerTasks
    * */
    private buildHeaderTasks(protocol?: IWinemakingProtocol) {
      this.headerTasks = {} as any;

      if (!protocol || !protocol.stages) return;


      // TODO: Improve HTML appearance when multiple tasks depend on the same one.
      for (let stage of protocol.stages) {
        if (!stage.tasks) stage.tasks = [];
        // order bytask created_at
        stage.tasks = _.sortBy(stage.tasks, 'created_at');
        stage.tasks.forEach(task => {
          if (!this.taskHasDependency(task)) {
            //push task to header
            const taskArray = this.headerTasks[stage.id] || [];
            taskArray.push(task);
            this.headerTasks[stage.id] = taskArray;
          }
        });
      }

      // Use $timeout to ensure the view has finished rendering
      // this.$timeout(() => this.adjustFirstSpaceHeight());
    }

    /*
      * Builds the sequence of stages based on the previous_stage_id of each stage
     */
    private buildStageSequence(protocol: IWinemakingProtocol) {
      const sequence = [];

      if (!protocol || !protocol.stages) return;

      const start: IWinemakingStage = _.find(protocol.stages, {previous_stage_template: null}) as IWinemakingStage;

      let currentStage: IWinemakingStage = start;
      while (currentStage) {
        sequence.push(currentStage);
        currentStage = _.find(protocol.stages, {previous_stage_template: currentStage.id}) as IWinemakingStage;
      }

      this.stagesSequence = sequence;
    }

    // we need this to make sure all the protocol sequences are aligned
    private adjustFirstSpaceHeight() {

      var elements = document.querySelectorAll('.first-space');

      var maxHeight = Array.from(elements).reduce(function (max, element) {
        var height = (element as HTMLElement).offsetHeight;
        return height > max ? height : max;
      }, 0);

      Array.from(elements).forEach(function (element) {
        //if not the same height, set the height to the max height
        if ((element as HTMLElement).offsetHeight !== maxHeight)
          (element as HTMLElement).style.height = maxHeight + 'px';

      });
    }


  }

  App.component('wgProtocolDetailed', {
    templateUrl: 'app/views/partials/wg-protocol-detailed.html',
    transclude: true,
    controller: WgProtocolDetailed as any,
    controllerAs: 'ctrl',
    bindings: {
      protocol: '='
    },
    // require: {
    //     testBed: '^testBed'
    // }

  });
}